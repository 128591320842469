import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';

const EquipmentSelector = ({ nbStep, isSCI, NAFsection, nbCandtionMicroValide, proprioBatimentImmoQ, moveToStep, goBack, raisonSociale, isMicroEntreprise, equipInstallationsSoumisVGPQ, setEquipInstallationsSoumisVGPQ }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');


    const handleConfirm = () => {
        if (isSCI) {
            moveToStep('LoyerProprioBailleur')
        } else {
            if (proprioBatimentImmoQ.includes("HABIT") ||
                proprioBatimentImmoQ.some(value => value.startsWith('BUR')) ||
                proprioBatimentImmoQ.some(value => value.startsWith('COM')) ||
                proprioBatimentImmoQ.includes("INDUSTRIAL")) {

                console.log("EquipmentSelector: =====> ClassementLogementDPE ",);
                moveToStep('ClassementLogementDPE');
            } else if (['G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U'].includes(NAFsection) &&
                (proprioBatimentImmoQ.includes("EXPOCONG") ||
                    proprioBatimentImmoQ.includes("PROLIB") ||
                    proprioBatimentImmoQ.some(value => value.startsWith('BUR')) ||
                    proprioBatimentImmoQ.some(value => value.startsWith('COM')) ||
                    proprioBatimentImmoQ.some(value => value.startsWith('PARK')) ||
                    proprioBatimentImmoQ.some(value => value.startsWith('STOCK')) ||
                    proprioBatimentImmoQ.includes("INDUSTRIAL"))
            ) {
                console.log("EquipmentSelector: =====> surfaceExploitationSup1000mC ",);
                moveToStep('surfaceExploitationSup1000mC')
            } else {
                console.log("EquipmentSelector: =====> surfaceExploitationSup1000mC ",);
                moveToStep('personnalDataSelection')
            }
        }
    };

    // const toggleSelectedValue = (key) => {
    //     if (equipInstallationsSoumisVGPQ.includes(key)) {
    //         setEquipInstallationsSoumisVGPQ(equipInstallationsSoumisVGPQ.filter((item) => item !== key));
    //     } else {
    //         setEquipInstallationsSoumisVGPQ([...equipInstallationsSoumisVGPQ, key]);
    //     }
    // };

    const toggleSelectedValue = (key) => {
        setEquipInstallationsSoumisVGPQ(prevState => {
            if (key === "*AO") {
                if (prevState.includes("*AO")) {
                    return [];
                }
                return ["*AO"];
            }
            if (prevState.includes("*AO")) {
                return [key];
            }

            if (prevState.includes(key)) {
                return prevState.filter(item => item !== key);
            }
            return [...prevState, key];
        });
    };

    const options = [
        { key: 'AIREJEU', label: { fr: 'Aire collective de jeux', en: 'Collective play area' } },
        { key: 'LEVAGE', label: { fr: 'Appareil de levage', en: 'Lifting equipment' } },
        {
            key: 'ELEV',
            label: {
                fr: 'Ascenseur, monte-charge, porte ou portail automatique ou semi-automatique',
                en: 'Lift, goods lift, automatic or semi-automatic door or gate'
            }
        },
        { key: 'SSPRESSFIXE', label: { fr: 'Equipement sous pression fixe', en: 'Fixed pressure equipment' } },
        { key: 'SSPRESSAMOV', label: { fr: 'Equipement sous pression amovible', en: 'Removable pressure equipment' } },
        { key: 'ECHAFAU', label: { fr: 'Echafaudage ou échelle', en: 'Scaffolding or ladder' } },
        { key: 'EPI', label: { fr: 'Equipement de protection individuelle (EPI)', en: 'Personal protective equipment (PPE)' } },
        { key: 'SPORT', label: { fr: 'Equipement sportif', en: 'Sports equipment' } },
        { key: 'FFRIGO', label: { fr: 'Fluide frigorigène', en: 'Refrigerant' } },
        {
            key: 'NRJTHERM',
            label: {
                fr: "Installation consommant de l'énergie thermique (chaudière dont la puissance nominale est comprise entre 400 kW et 5 MW)",
                en: 'Plant consuming thermal energy (boiler with a rated output of between 400 kW and 5 MW)'
            }
        },
        {
            key: 'MEDICAL',
            label: {
                fr: 'Installation ou dispositif médical',
                en: 'Medical installation or device'
            }
        },
        { key: 'MACHINE', label: { fr: 'Machine de travail (hors appareil de levage)', en: 'Work machinery (excluding lifting equipment)' } },
        { key: 'PISCINE', label: { fr: 'Piscine', en: 'Piscines' } },
        { key: '*AO', label: { fr: '- aucune de ces options -', en: '- none of these options -' } },
    ];


    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );



    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);




    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} possède les équipements ou installations suivantes :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} has the following equipment and facilities:
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="mb-4">
                    <input
                        type="text"
                        className="w-full p-3 border-2 border-gray-200 rounded-2xl"
                        placeholder={i18n.language === 'fr' ? "Titre ou mot-clé" : "Title or keyword"}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold rounded-xl ${equipInstallationsSoumisVGPQ.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc] '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(equipInstallationsSoumisVGPQ.length !== 0)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default EquipmentSelector;
