import React, { createContext, useContext, useEffect, useState } from 'react';
import { db } from "../firebase.config";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { updateMessage, update_last_system_message_tokens, update_last_system_message_documents, createMessage } from '../utils/conversation';
import { getAuth, applyActionCode } from "firebase/auth";
import { fetchData } from '../utils/ask_api';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getEnvConfig } from '../utils/config';


// Create a context with initial values
const AppContext = createContext();
const { wsUrl } = getEnvConfig()
console.log('Using WebSocket URL:', wsUrl);

var ws = new WebSocket(wsUrl);

// Create a provider component to wrap your app with the context provider
export const AppContextProvider = ({ children }) => {


    const initialMsg = createMessage(

        'Bonjour, je suis Oliver votre nouvel assistant juridique (version beta), comment puis-je vous aider aujourd\'hui ?',
        "system",
        undefined,
        {},
        -1
    )

    const subdomain = window.location.hostname.split('.')[0];

    // Version is 0 or 1
    const [version, setVersion] = useState(0)

    const [selectedParentIds, setSelectedParentIds] = useState([]);
    const [parentIds, setParentIds] = useState([]);

    const [customOliver, setCustomOliver] = useState(false)

    const [messages, setMessages] = useState([initialMsg]);
    const [compagnyInfos, setCompagnyInfos] = useState({
        "secteur_activite_principale": null,
        "forme_juridique": null,
        "effectif_moyen": null,
        "chiffre_affaires": null,
        "bilan": null
    });
    const [model, setModel] = useState("");
    const [nameModel, setNameModel] = useState("");
    const [liensUtiles, setLiensUtiles] = useState([]);
    const [experts, setExperts] = useState([]);

    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)

    const [computingResponse, setComputingResponse] = useState(false);
    const [writing, setWriting] = useState(false);

    const [streamError, setStreamError] = useState(null)


    const [currentUser, setCurrentUser] = useState(null);
    const [credits, setCredits] = useState(null);
    const [creditUnitPrice, setCreditUnitPrice] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [legalContent, setLegalContent] = useState('');


    const [configCreditOliver, setConfigCreditOliver] = useState(null);

    const [cgus, setCgus] = useState(false);

    // useEffect(() => {
    //     const unsubscribe = onSnapshot(doc(db, "oliver/app_settings"), (doc) => {

    //         const data = doc.data();
    //         setNameModel(data.default_model)
    //         switch (data.default_model) {
    //             case "Open Mixtral 8x7b":
    //                 setModel("mistral")
    //                 setVersion(0)
    //                 break;
    //             case "Mistral medium":
    //                 setModel("mistral")
    //                 setVersion(1)
    //                 break;
    //             case "GPT 3.5":
    //                 setModel("openai")
    //                 setVersion(0)
    //                 break;
    //             case "GPT 4 turbo":
    //                 setModel("openai")
    //                 setVersion(1)
    //                 break;
    //             default:
    //                 console.log(data.default_model)
    //         }

    //     });

    //     return () => unsubscribe();
    // // }, []);

    // // Parents ids 
    // useEffect(() => {
    //     const unsubscribe = onSnapshot(doc(db, "oliver/parent_ids"), (doc) => {

    //         const data = doc.data();
    //         setParentIds(data);
    //         setSelectedParentIds(Object.keys(data));

    //     });

    //     // Unsubscribe from changes when the component is unmounted or dependencies change
    //     return () => unsubscribe();
    // }, []);

    // Parents ids 
    // useEffect(() => {
    //     const unsubscribe = onSnapshot(doc(db, "oliver/custom_oliver"), (doc) => {
    //         const data = doc.data();
    //         setCustomOliver(data);
    //     });

    //     // Unsubscribe from changes when the component is unmounted or dependencies change
    //     return () => unsubscribe();
    // }, []);

    // useEffect(() => {
    //     const unsubscribe = onSnapshot(doc(db, "oliver/cgus"), (doc) => {
    //         const data = doc.data();
    //         setCgus(data);
    //     });
    //     // Unsubscribe from changes when the component is unmounted or dependencies change
    //     return () => unsubscribe();
    // }, []);
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        if (currentUser) {
            const unsubscribe = onSnapshot(doc(db, `users/${currentUser.uid}`), (doc) => {
                const data = doc.data();
                if (data) {
                    setUserData(data)
                }
            });
            // Unsubscribe from changes when the component is unmounted or dependencies change
            return () => unsubscribe();
        }
        else {
            console.log("on est la")
        }
    }, [db, currentUser]);

    useEffect(() => {
        if (currentUser) {
            const unsubscribe = onSnapshot(doc(db, `users/${currentUser.uid}`), (doc) => {
                const data = doc.data();
                if (data) {
                    setCredits(data.credits)
                }
            });

            // Unsubscribe from changes when the component is unmounted or dependencies change
            return () => unsubscribe();
        }
        else {
            setVersion(0)
            setCredits(null)
        }
    }, [db, currentUser]);

    // useEffect(() => {
    //     if (!credits) {
    //         setVersion(0)
    //     }
    // }, [credits])


    // useEffect(() => {
    //     var parentIdskeys = Object.keys(parentIds)
    //     const option_list = [
    //         { "name": "Checkup d'une entreprise", "class": "filter_button_checkup_entreprise", "action": { "name": "checkup_entreprise" } },
    //         { "name": "formalités d'entreprise", "class": "filter_button_suivre_formalite", "action": { "name": "formalités_d_entreprise" } },
    //     ]
    //     const optionsInfogreffe_list = [
    //         { "name": "Les décisions de justice", "class": "filter_button_decision_justice", "action": { "name": "filter", "parentIds": [] } },
    //         { "name": "Les démarches administratives", "class": "filter_button_demarche_administrative", "action": { "name": "filter", "parentIds": ["vos_droits"] } },
    //         { "name": "La réglementation française", "class": "filter_button_reglementation_francaise", "action": { "name": "filter", "parentIds": parentIdskeys.filter(key => key.startsWith("legitext")) } },
    //         { "name": "Les services Infogreffe", "class": "filter_button_service_infogreffe", "action": { "name": "filter", "parentIds": ["fiche_renforcement_infogreffe"] } },
    //     ]

    //     if (subdomain == "infogreffe") {
    //         setOptions(option_list)
    //         // setOptions(optionsInfogreffe_list)
    //     }
    // }, [parentIds]);

    // Filters
    // useEffect(() => {
    //     if (selectedOption) {
    //         var newMsg = createMessage("", "user", undefined, {}, -1);
    //         updateMessage(newMsg, setMessages);
    //         const action = selectedOption["action"]
    //         if (action["name"] == "filter") option_filter()
    //         else if (action["name"] == "checkup_entreprise") option_checkup_entreprise()
    //         else if (action["name"] == "suivre_formalite") option_suivre_formalite()
    //         else if (action["name"] == "formalités_d_entreprise") {
    //             option_formalités_d_entreprise()
    //         }
    //     }
    // }, [selectedOption]);


    const handleCloseWS = () => {
        setComputingResponse(false)
        setWriting(false)
        ws.close();
        console.log('WebSocket connection closed by user.');
    }

    const auth = getAuth();

    // const location = useLocation();

    // const queryParams = new URLSearchParams(location.search);
    // const mode = queryParams.get('mode');
    // const oobCode = queryParams.get('oobCode');
    // useEffect(() => {
    // if (mode && oobCode) {
    //     console.log("mode: " + mode)
    //     console.log("oobCode: " + oobCode)
    //     applyActionCode(auth, oobCode).then(() => {
    //         // Email address has been verified.
    //         alert("Votre email a été vérifié avec succès !")
    //         const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    //         window.history.pushState({ path: newUrl }, '', newUrl);

    //     }).catch((error) => {
    //         alert("Erreur lors de la vérification de votre email")
    //         const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    //         window.history.pushState({ path: newUrl }, '', newUrl);
    //     });
    // }
    // }, [mode, oobCode])



    const getAccessToken = () => {
        const user = auth.currentUser;
        if (user) {
            const token = user.getIdTokenResult().then((token) => {
                return token.token
            })
            return token;
        }
        else return null;
    }


    const retryMessage = async () => {
        // Check if the WebSocket connection is open
        if (ws.readyState === WebSocket.OPEN) {
            setStreamError(false)
            try {
                const conversation = [...messages].filter(msg => msg.id != -1)
                ws.send(JSON.stringify({
                    // conversation: [...messages, newMsg].slice(1),
                    //Ignore historique
                    conversation: conversation,
                    selectedCodes: selectedParentIds,
                    compagnyInfos: compagnyInfos,
                    version: version,
                    model: model,
                    name_model: nameModel,
                    token: await getAccessToken()
                }));
            } catch (err) {
                window.alert(`Error: ${err} please try again later`);
            }
        } else if (ws.readyState === WebSocket.CONNECTING) {
            // If the WebSocket is still connecting, wait and try again after a brief delay
            setTimeout(() => {
                retryMessage();
            }, 500);
        } else {
            console.log("WebSocket closed");
            // If the WebSocket connection is closed, reconnect and then send the message
            ws.close();
            ws = new WebSocket(wsUrl);
            retryMessage();
        }
    }


    const sendMessage = async (formValue, setFormValue) => {
        // Check if the WebSocket connection is open
        console.log("formValue: ", formValue)
        // setSelectedOption(null);
        console.log("ws.readyState: ", ws.readyState)
        // setWriting(true);
        if (ws.readyState === WebSocket.OPEN) {
            var newMsg = createMessage(formValue, "user");
            console.log("newMsg: ", newMsg);
            setComputingResponse(true);
            setFormValue("");
            updateMessage(newMsg, setMessages);
            console.log("newMsg1: ", newMsg);

            try {
                const conversation = [...messages, newMsg].filter(msg => msg.id != -1)
                const data = JSON.stringify({
                    // conversation: [...messages, newMsg].slice(1),
                    //Ignore historique
                    conversation: conversation,
                    selectedCodes: selectedParentIds,
                    compagnyInfos: compagnyInfos,
                    version: version,
                    model: model,
                    name_model: nameModel,
                    token: await getAccessToken()
                })
                // console.log(data)
                ws.send(data);
                var newMsg = createMessage("", "system");
                updateMessage(newMsg, setMessages);
            } catch (err) {
                window.alert(`Error: ${err} please try again later`);
            }
        } else if (ws.readyState === WebSocket.CONNECTING) {
            // If the WebSocket is still connecting, wait and try again after a brief delay
            setTimeout(() => {
                sendMessage(formValue, setFormValue);
            }, 500);
        } else {
            console.log("WebSocket closed");
            // If the WebSocket connection is closed, reconnect and then send the message
            ws.close();
            ws = new WebSocket(wsUrl);
            sendMessage(formValue, setFormValue);
        }
    };


    ws.onmessage = function (event) {
        const jsonSocket = JSON.parse(event.data);
        // console.log("onmessage: ", jsonSocket)
        if (jsonSocket["type"] === "documents") {
            update_last_system_message_documents(jsonSocket["content"], setMessages)
        }
        else if (jsonSocket["type"] === "liens_utiles") {
            setLiensUtiles(jsonSocket["content"])
        }
        else if (jsonSocket["type"] === "experts") {
            setExperts(jsonSocket["content"])
        }
        else if (jsonSocket["type"] === "token") {
            if (jsonSocket["content"] == null) {
                setWriting(false)
            }
            else {
                if (!writing) setWriting(true)
                update_last_system_message_tokens(jsonSocket["content"], setMessages)
            }
        }
        else if (jsonSocket["type"] === "status") {
            if (jsonSocket["content"] == "streaming_error") {
                setWriting(false)
                setStreamError(true)
            }
            else if (jsonSocket["content"] == "end_response") {
                setComputingResponse(false)
            }
        }
        else {
            console.log("Error: unknown message type" + jsonSocket["type"])
        }

    };

    // useEffect(() => {
    //     async function fetchConfig() {
    //         const response = await fetchData("config-credit-oliver", null, "GET");
    //         setConfigCreditOliver(response)
    //     }
    //     if (currentUser) {
    //         // console.log("currentUser :", currentUser);
    //         fetchConfig();
    //     }
    // }, [currentUser])

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "site-content/LegalContent"), (doc) => {
            const data = doc.data();
            setLegalContent(data);
            console.log("site-content/LegalContent", data)
        });
        // Unsubscribe from changes when the component is unmounted or dependencies change
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (configCreditOliver) {
            setCreditUnitPrice(configCreditOliver.unitAmount / 100);
        }
    }, [configCreditOliver]);



    return (
        <AppContext.Provider value={{
            selectedParentIds,
            messages,
            compagnyInfos,
            liensUtiles,
            model,
            experts,
            parentIds,
            options,
            selectedOption,
            version,
            streamError,
            writing,
            computingResponse,
            currentUser,
            isAdmin,
            credits,
            creditUnitPrice,
            configCreditOliver,
            customOliver,
            cgus,
            subdomain,
            nameModel,
            userData,
            legalContent,

            setUserData,
            setSelectedParentIds,
            setMessages,
            setCompagnyInfos,
            setLiensUtiles,
            setModel,
            setExperts,
            setSelectedOption,
            setVersion,
            setStreamError,
            setCurrentUser,
            setIsAdmin,
            setWriting,
            setNameModel,
            sendMessage,
            retryMessage,
            handleCloseWS,
        }}>
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to access the context values
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
};
