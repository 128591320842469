import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getEnvConfig } from "../../utils/config";
import { Loader } from "../Loader";

// Charge Stripe avec ta clé publique
const stripePromise = loadStripe("pk_test_51PuH4IFogUVbkwou1z1Lp1t2Zu4jdzkvnsLBmAapXlvfNJVSeaErHBDb0a6xZ76JqBMbWuJAYc1OqvDoY5MMzPR500hsOt7fH3");

const DownloadButton = ({ result }) => {

    const handleDownload = async () => {
        // Stocker `result` dans le localStorage avant redirection
        setIsLoading(true);
        try {
            localStorage.setItem("result", JSON.stringify(result)); // `result` est l'objet que tu veux préserver

            const stripe = await stripePromise;
            const { apiUrl } = getEnvConfig();
            console.log("apiUrl: ", apiUrl + 'run_simulator')
            const response = await fetch(apiUrl + "create-checkout-session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    amount: 2990, // 9
                    result: result
                }),
            });

            const session = await response.json();

            // Rediriger vers la page de paiement Stripe
            const resultStripe = await stripe.redirectToCheckout({ sessionId: session.id });

            if (resultStripe.error) {
                console.error(resultStripe.error.message);
            }
        } catch (error) {
            console.error('There was a problem with the request:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            {isLoading && <Loader />}
            <p className="text-3xl md:text-4xl font-bold my-1">29,90€ <span className='text-xl font-bold relative bottom-3'>HT</span></p>
            <div className="flex justify-center">
                <button
                    className="bg-[#161C67] text-white px-16 py-2 rounded-full"
                    onClick={handleDownload}
                >
                    Télécharger
                </button>
            </div>
        </div>
    );
};

export default DownloadButton;
