import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const InterventionConvReglSASSCASA = ({ nbStep, isSCI, catJuridiqueNiv2, interventionConvReglSASSCASA, setInterventionConvReglSASSCASA, nbCandtionMicroValide, moveToStep, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {

        if (catJuridiqueNiv2 === "54") {
            moveToStep('interventionConvReglSARL')
        } else {
            if (interventionConvReglSASSCASA) {
                moveToStep('natureConvRegleIntevenues')
            } else {
                moveToStep('VehiculeDeSociete')
            }
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);





    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Des conventions sont-elles intervenues cette année ou en 2023, directement ou par personne interposée, entre {raisonSociale} et son président, l'un de ses dirigeants, l'un de ses actionnaires avec plus de 10% des droits de vote ou, s'il s'agit d'une société actionnaire, la société la contrôlant ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Have any agreements been entered into this year or in 2023, directly or through an intermediary, between {raisonSociale} and its chairman, any of its officers, any of its shareholders with more than 10% of the voting rights or, in the case of a corporate shareholder, the company controlling it?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${interventionConvReglSASSCASA === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setInterventionConvReglSASSCASA(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${interventionConvReglSASSCASA === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setInterventionConvReglSASSCASA(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(interventionConvReglSASSCASA !== null)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default InterventionConvReglSASSCASA;
