import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const ClassementLogementDPE = ({ nbStep, isSCI, classementLogementDPE, NAFsection, proprioBatimentImmoQ,
    setClassementLogementDPE, moveToStep, goBack, raisonSociale, nbCandtionMicroValide, isMicroEntreprise, NAFgroupe }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');

    console.log("NAFgroupe: ", NAFgroupe)
    const handleConfirm = () => {
        if (isSCI) {
            moveToStep('DetentionSiteInternet');
        } else if (['G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U'].includes(NAFsection) &&
            (proprioBatimentImmoQ.includes("EXPOCONG") ||
                proprioBatimentImmoQ.includes("PROLIB") ||
                proprioBatimentImmoQ.some(value => value.startsWith('BUR')) ||
                proprioBatimentImmoQ.some(value => value.startsWith('COM')) ||
                proprioBatimentImmoQ.some(value => value.startsWith('PARK')) ||
                proprioBatimentImmoQ.some(value => value.startsWith('STOCK')) ||
                proprioBatimentImmoQ.includes("INDUSTRIAL"))
        ) {
            moveToStep('surfaceExploitationSup1000mC')
        } else {
            moveToStep('personnalDataSelection')
        }
    }

    // Fonction pour ajouter/retirer une option sélectionnée
    const toggleSelectedValue = (key) => {
        if (classementLogementDPE.includes(key)) {
            setClassementLogementDPE(classementLogementDPE.filter((item) => item !== key));
        } else {
            setClassementLogementDPE([...classementLogementDPE, key]);
        }
    };


    const options = [
        { key: 'ABC', label: { fr: 'A, B ou C', en: 'A, B or C' } },
        { key: 'DE', label: { fr: 'D ou E', en: 'D or E' } },
        { key: 'FG', label: { fr: 'F ou G', en: 'F or G' } },
        { key: '*NOSE', label: { fr: '- je ne sais pas -', en: '- I don\'t know -' } },
    ];


    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Classement DPE du ou des logements dont est propriétaire {raisonSociale} :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            ECD classification of the property(ies) owned by {raisonSociale} :
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold  rounded-xl ${classementLogementDPE.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc] '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(classementLogementDPE.length !== 0)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default ClassementLogementDPE;
