import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const LocationMeubleDeTourismeQ = ({ nbStep, locationMeubleDeTourismeQ, locMeubleeNonMeubleeQ, nbCandtionMicroValide, setLocationMeubleDeTourismeQ, isSCI, moveToStep, goBack, raisonSociale, isMicroEntreprise, NAFgroupe }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        if (["55.1", "55.3"].includes(NAFgroupe) && locMeubleeNonMeubleeQ.includes("MEUBLEE")) {
            moveToStep('locHabitPersoProprioQ');
        } else {
            console.log("HEREEE assujettissementLoyerProComTvaQ")
            moveToStep('assujettissementLoyerProComTvaQ')
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Le logement meublé est loué à une clientèle qui respectent les trois caractéristiques suivantes :
                            <ul className='list-disc pl-6 text-left'>
                                <li>elle a l'usage exclusif du logement loué (le propriétaire n'est pas présent durant la location)</li>
                                <li> elle utilise le logement comme résidence secondaire et non comme domicile</li>
                                <li> elle y réside pour une courte durée (location à la journée/semaine/mois) et maximum 90 jours</li>
                            </ul>
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Furnished accommodation is rented to customers who meet the following three criteria:
                            <ul className='list-disc pl-6 text-left'>
                                <li>they have exclusive use of the rented accommodation (the owner is not present during the rental period)</li>
                                <li>they use the accommodation as a secondary residence and not as their home</li>
                                <li>they reside there for a short period (daily/weekly/monthly rental) and maximum 90 days</li> </ul>.
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${locationMeubleDeTourismeQ === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setLocationMeubleDeTourismeQ(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${locationMeubleDeTourismeQ === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setLocationMeubleDeTourismeQ(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(locationMeubleDeTourismeQ !== null)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default LocationMeubleDeTourismeQ;
