import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';
import { useNavigate } from 'react-router-dom';
import { getEnvConfig } from '../../utils/config';
import { Loader } from '../Loader';


const VehiculeDeSociete = ({ nbStep, isSCI, moveToStep, nbCandtionMicroValide, voitureVehiculeDeSocieteQ, setVoitureVehiculeDeSocieteQ, nbMoyenSalaries, goBack, raisonSociale, isMicroEntreprise,
    SIRET,
    isComanyCommercial,
    codeNaf,
    NAFsection,
    catJuridiqueNiv1,
    catJuridiqueNiv2,
    catJuridiqueNiv3,
    dateCreation,
    adressPostal,
    chiffredAffairesTotalNetX,
    regimeTVANormalSimplifieFranQ,
    optionImpotSocieteISQ,
    auMoins90pCAsoumisTVA,
    montantTVAdeclareSup15kQ,
    proprioBatimentImmoQ,
    loyerProprioBailleur,
    equipInstallationsSoumisVGPQ,
    classementLogementDPE,
    surfaceExploitationSup1000mC,
    traitementDonneesPerso,
    sousTraitantDonneesPersonnelles,
    collectionDonnesPerso,
    traitementListeAIPDoblig,
    criteresDesignationDPO,
    detentionSiteInternetQ,
    utilisationTraceurCookies,
    operateurPlateforme,
    prodDetDechets5flux,
    prodDetenCollecteEliminDechetsQ,
    etabliRecevantPublicERP,
    SiegeLocauxEnCommunX,
    dateDomiciliationLocauxCommun,
    cocontractantPrestationSup5k,
    versHonorairesComSup1200,
    emissionFactureSecteurPublic,
    datePremClotureX,
    bilan_totalBilanQ,
    totalCapitauxPropres,
    capitalSocial,
    societeFilialeMereQ,
    typeControlExerceSociete,
    depasSeuilConsoCA48mBi24mEff250Q,
    interventionConvReglSASSCASA,
    interventionConvReglSARL,
    natureConvRegleIntevenues,
    activityDetails

}) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const decomposeCodeNaf = (code) => {
        const parts = code.match(/^(\d{2})\.(\d{2})([A-Z])$/);
        if (!parts) return null;

        return {
            NAFdivision: parts[1],
            NAFgroupe: `${parts[1]}.${parts[2].charAt(0)}`,
            NAFclasse: `${parts[1]}.${parts[2]}`,
            NAFsousClasse: code
        };
    };

    const handleConfirm = async () => {
        if (nbMoyenSalaries > 0) {
            moveToStep('VersementPayeType');
        } else {
            setIsLoading(true);
            const postData = [{
                scope: {
                    types: ["Obligation", "droit"],
                    categories: [
                        "Compliance",
                        "Comptabilite - Gestion - Finances",
                        "Environnement - Developpement durable",
                        "Fiscalite - Impots - Taxes",
                        "Gouvernance - Engagements",
                        "Ressources humaines",
                        "Metier - Activite"
                    ],
                    laws: ["France", "Europe"],
                    modules_only: false,
                    module_id: null
                },
                variables: {
                    SIRET: SIRET,
                    SIREN: SIRET?.substring(0, 9) || '',
                    raisonSociale: raisonSociale,
                    dateCreation: dateCreation,
                    adressPostal: adressPostal,
                    catJuridiqueNiv1: catJuridiqueNiv1,
                    catJuridiqueNiv2: catJuridiqueNiv2,
                    catJuridiqueNiv3: catJuridiqueNiv3,
                    activityDetails: activityDetails,
                    NAFsection: NAFsection,
                    ...decomposeCodeNaf(codeNaf),

                    'resultat[2023].chiffredAffairesTotalNet': Math.ceil(chiffredAffairesTotalNetX),
                    'nbMoyenSalaries[2023]': Math.ceil(nbMoyenSalaries),
                    regimeTVANormalSimplifieFranQ: regimeTVANormalSimplifieFranQ,
                    optionImpotSocieteISQ: optionImpotSocieteISQ,
                    auMoins90pCAsoumisTVA: auMoins90pCAsoumisTVA,
                    montantTVAdeclareSup15kQ: montantTVAdeclareSup15kQ,
                    proprioBatimentImmoQ: proprioBatimentImmoQ,
                    loyerProprioBailleur: loyerProprioBailleur,
                    equipInstallationsSoumisVGPQ: equipInstallationsSoumisVGPQ,
                    classementLogementDPE: classementLogementDPE,
                    'entite.gestion.surfaceExploitationSup1000mC': surfaceExploitationSup1000mC,
                    traitementDonneesPerso: traitementDonneesPerso,
                    sousTraitantDonneesPersonnelles: sousTraitantDonneesPersonnelles,
                    collectionDonnesPerso: collectionDonnesPerso,
                    traitementListeAIPDoblig: traitementListeAIPDoblig,
                    criteresDesignationDPO: criteresDesignationDPO,
                    detentionSiteInternetQ: detentionSiteInternetQ,
                    utilisationTraceurCookies: utilisationTraceurCookies,
                    operateurPlateforme: operateurPlateforme,
                    prodDetDechets5flux: prodDetDechets5flux,
                    prodDetenCollecteEliminDechetsQ: prodDetenCollecteEliminDechetsQ,
                    etabliRecevantPublicERP: etabliRecevantPublicERP,
                    SiegeLocauxEnCommunX: SiegeLocauxEnCommunX,
                    dateDomiciliationLocauxCommun: dateDomiciliationLocauxCommun,
                    cocontractantPrestationSup5k: cocontractantPrestationSup5k,
                    versHonorairesComSup1200: versHonorairesComSup1200,
                    emissionFactureSecteurPublic: emissionFactureSecteurPublic,
                    datePremClotureX: datePremClotureX,
                    'bilan.totalBilanQ': Math.ceil(bilan_totalBilanQ),
                    totalCapitauxPropres: Math.ceil(totalCapitauxPropres),
                    capitalSocial: Math.ceil(capitalSocial),
                    societeFilialeMereQ: societeFilialeMereQ,
                    typeControlExerceSociete: typeControlExerceSociete,
                    depasSeuilConsoCA48mBi24mEff250Q: depasSeuilConsoCA48mBi24mEff250Q,
                    interventionConvReglSASSCASA: interventionConvReglSASSCASA,
                    interventionConvReglSARL: interventionConvReglSARL,
                    natureConvRegleIntevenues: natureConvRegleIntevenues,
                    voitureVehiculeDeSocieteQ: voitureVehiculeDeSocieteQ
                }
            }];
            console.log(postData);

            try {
                const { apiUrl } = getEnvConfig();
                console.log("apiUrl: ", apiUrl + 'run_simulator')
                const response = await fetch(apiUrl + 'run_simulator', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                console.log(result)
                if (result?.erros) {
                    alert("Une erreur s'est produite lors du calcul des obligations.")
                    navigate('/questionnaire');
                }
                // navigate('/workspace', { state: { result, raisonSociale } });
                // Stocker result et raisonSociale dans localStorage
                localStorage.setItem('result', JSON.stringify(result));
                localStorage.setItem('raisonSociale', raisonSociale);

                // Naviguer vers /workspace sans passer de state
                navigate('/workspace');

            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
                alert("Une erreur s'est produite lors du calcul des obligations.")
                navigate('/questionnaire');
            } finally {
                setIsLoading(false);
            }

        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);


    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            {isLoading && <Loader />}
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} possède ou utilise des voitures de société, destinées au transport de passager ou à usage multiple (destinées principalement au transport de personnes) ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} owns or uses company cars, either for passenger transport or for multiple use (mainly for passenger transport)?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${voitureVehiculeDeSocieteQ === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setVoitureVehiculeDeSocieteQ(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${voitureVehiculeDeSocieteQ === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setVoitureVehiculeDeSocieteQ(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(voitureVehiculeDeSocieteQ !== null)}
                    onConfirm={handleConfirm}
                    isLastQst={nbMoyenSalaries === 0 && isComanyCommercial}
                />
            </div>
        </div>
    );
};

export default VehiculeDeSociete;
