import { useEffect, useState } from "react";

export const Loader = () => {
    const [message, setMessage] = useState("");

    useEffect(() => {
        const timings = [
            { time: 1000, text: "Veuillez patientez quelques instants..." },
            { time: 9000, text: "Nous traitons votre demande..." },
            { time: 14000, text: "Compilation des contenus…" },
            { time: 19000, text: "Compilations des solutions..." },
            { time: 24000, text: "C'est presque terminé..." },
            { time: 99000, text: "Tenez bon, ça arrive !" }
        ];

        const timers = timings.map(({ time, text }) =>
            setTimeout(() => setMessage(text), time)
        );

        return () => timers.forEach(clearTimeout);
    }, []);

    return (
        <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 mb-4"></div>
            <p className="text-white text-lg animate-fade-in transition-all duration-500">
                {message}
            </p>
        </div>
    );
};