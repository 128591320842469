import React from 'react';
import card_fiscal from '../../assets/card_fiscal.svg';
import card_gestion from '../../assets/card_gestion.svg';
import card_environnemental from '../../assets/card_environnemental.svg'
import card_social from '../../assets/card_social.svg'
import noun_autorized from '../../assets/noun-autorized-4504732.svg'
import noun_shareholder from '../../assets/noun-shareholder-4244455.svg'
import medicine_health from '../../assets/medical-medicine-health-23-svgrepo-com.svg'
import droit_mesure from '../../assets/noun-puzzle-6418167_white.png'

import formulaire from '../../assets/solutions/formulaire.svg'
import instruction from '../../assets/solutions/instruction.svg'
import prestationService from '../../assets/solutions/prestationService.svg'
import product from '../../assets/solutions/product.svg'
import serviceNumeriquee from '../../assets/solutions/serviceNumeriquee.svg'
import teleservice from '../../assets/solutions/teleservice.svg'
import model_doc from '../../assets/solutions/model_doc.svg'


const ThematicBlock = ({ type, index, title, details, obligations }) => {
    const getColor = (title) => {
        switch (title) {
            case 'Fiscalite - Impots - Taxes':
                return '#ffd23e';
            case 'Comptabilite - Gestion - Finances':
                return '#eb356c';
            case 'Environnement - Developpement durable':
                return '#40c9a0';
            case 'Ressources humaines':
                return '#75aaff';
            case 'Compliance':
                return '#da92f0';
            case 'Gouvernance - Engagements':
                return '#babbc0'
            case "Santé de l'Homme et de son environnement de travail":
                return '#161C67'
            case "Metier - Activite":
                return '#B29786';

            // Solutions :
            case "Instructions":
                return '#222222';
            case 'Prestation de service':
                return '#1c5d99';
            case 'Produit':
                return '#639fab';
            case 'Formulaire Cerfa':
                return '#bbcde5';
            case "Téléservice":
                return '#da92f0'
            case 'Service numérique':
                return '#a9e4ef';
            case 'Modèle de document':
                return '#babbc0'


            default:
                return '#161C67';
        }
    }

    function getIcon(title) {
        switch (title) {
            case 'Fiscalite - Impots - Taxes':
                return card_fiscal;
            case 'Comptabilite - Gestion - Finances':
                return card_gestion;
            case 'Environnement - Developpement durable':
                return card_environnemental;
            case 'Ressources humaines':
                return card_social;
            case 'Compliance':
                return noun_autorized;
            case 'Gouvernance - Engagements':
                return noun_shareholder;
            case "Santé de l'Homme et de son environnement de travail":
                return medicine_health;
            case "Metier - Activite":
                return droit_mesure;

            // Solutions :
            case "Instructions":
                return instruction;
            case 'Prestation de service':
                return prestationService;
            case 'Produit':
                return product;
            case 'Formulaire Cerfa':
                return formulaire;
            case "Téléservice":
                return teleservice;
            case 'Service numérique':
                return serviceNumeriquee;
            case 'Modèle de document':
                return model_doc;
            default:
                return null;
        }
    }

    return (
        <div key={index} className="w-full">
            <div className="card-category card-category-fis">
                <div className="border-left" style={{ 'background-color': getColor(title) }}>
                    <img src={getIcon(title)} alt="" className="border-left--img" />
                </div>
                <div className="card-category--content hover:bg-[#F7F7F7] ">
                    <div className="card-category__header rounded-md bg-white">
                        <span className="text-sm font-bold leading-7 pl-2 uppercase text-[#1e4690] break-words">
                            {title} {/* Affiche le type */}
                        </span>
                        {type === "solution" ?
                            <div className={`card-category__number-${type}`}>
                                <p className="card-category__number--value">{obligations}</p>
                                <svg className="dashed-circle" width="60" height="60" viewBox="0 0 60 60">
                                    <circle cx="30" cy="30" r="29" stroke="#1e4690" strokeWidth="2" fill="none" strokeDasharray="8,4"></circle>
                                </svg>
                            </div>
                            :
                            <div className={`card-category__number-${type}`}>
                                <p className="card-category__number--value">{obligations}</p>
                            </div>
                        }
                    </div>
                    {details && details?.length > 0 &&
                        <>
                            <p className="card-category__detail mb-2">Sous-thématique(s)</p>
                            <div className="grid grid-cols-1 sm:grid-cols-1 gap-1">
                                {details.map((detail, index) => (
                                    <div key={index} className="bg-[#F7F7F7] rounded-[5px] h-9 basis-[calc(50%_-_15px)] hover:bg-[#fff] flex justify-between items-center p-2 sm:col-span-1 col-span-1">
                                        <p className="card-category__item--text flex-grow">{detail.name}</p>
                                        <div className="card-category__item__number">
                                            <span className="card-category__item__number--value">{detail.count}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};


export default ThematicBlock;
