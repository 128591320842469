import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { themePopOver, updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';

const RevenuFiscalReference = ({ nbStep, isSCI, moveToStep, goBack, nbCandtionMicroValide, handleValidateStep, raisonSociale, isMicroEntreprise, revenuFiscalReference, setRevenuFiscalReference }) => {
    console.log("RevenuFiscalReference: ", nbCandtionMicroValide);

    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const rangeRef = useRef(null);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        if (revenuFiscalReference) { handleValidateStep('RevenuFiscalReference'); moveToStep('versementLiberatoireIR'); }
        else {

            moveToStep('SiegeLocauxEnCommunX');
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);



    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] -mt-2'>

                <h1 className='text-2xl text-center text-[#1e4690] my-3'>
                    {i18n.language === 'fr' &&
                        <>
                            <p className="text-left mb-2">Le revenu fiscal de référence 2021 de {raisonSociale} est-il inférieur ou égal à :</p>
                            <ul className="list-disc pl-6 text-left">
                                <li>26 070 € pour une personne seule (1 part),</li>
                                <li>52 140 € pour un couple (2 parts) sans enfant,</li>
                                <li>65 175 € pour un couple (2 parts) avec 1 enfant (1/2 part),</li>
                                <li>78 210 € pour un couple avec 2 enfants (3 parts),</li>
                            </ul>
                        </>

                    }

                    {i18n.language === 'en' &&
                        <>
                            <p className="text-left mb-2">Is {raisonSociale}'s 2021 reference tax income less than or equal to:</p>
                            <ul className="list-disc pl-6 text-left">
                                <li>€26,070 for a single person (1 part),</li>
                                <li>€52,140 for a couple (2 parts) without children,</li>
                                <li>€65,175 for a couple (2 parts) with 1 child (1/2 part),</li>
                                <li>€78,210 for a couple with 2 children (3 parts),</li>
                            </ul>
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-6'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${revenuFiscalReference === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]  hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]'}`}
                        onClick={() => setRevenuFiscalReference(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${revenuFiscalReference === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]  hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]'}`}
                        onClick={() => setRevenuFiscalReference(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(revenuFiscalReference !== null)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default RevenuFiscalReference;
