import React, { useState, useMemo, useEffect } from 'react';
import { ChevronDown, ChevronRight, Search } from 'lucide-react';
import { buildNafHierarchy, codes_naf_fr } from '../../utils/anpi';

const NAFCodeSelector = ({ onBack, onValidate }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedNodes, setExpandedNodes] = useState(new Set());
    const [selectedCode, setSelectedCode] = useState(null);

    const nafHierarchy = useMemo(() => buildNafHierarchy(codes_naf_fr), []);

    // Fonction pour collecter tous les IDs parents d'un nœud correspondant
    const collectParentIds = (items, targetId, path = new Set()) => {
        for (const item of items) {
            if (item.id === targetId) {
                return true;
            }
            if (item.children && item.children.length > 0) {
                path.add(item.id);
                const found = item.children.some(child =>
                    collectParentIds([child], targetId, path)
                );
                if (found) {
                    return true;
                }
                path.delete(item.id);
            }
        }
        return false;
    };

    // Fonction pour collecter tous les IDs des nœuds qui correspondent à la recherche
    const collectMatchingNodeIds = (items, searchTerm) => {
        const matchingIds = new Set();

        const searchRecursive = (item) => {
            const matchesSearch =
                item.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.code.toLowerCase().includes(searchTerm.toLowerCase());

            if (matchesSearch) {
                // Ajouter l'ID du nœud correspondant
                matchingIds.add(item.id);
                // Collecter tous les IDs parents
                collectParentIds(nafHierarchy, item.id, matchingIds);
            }

            if (item.children) {
                item.children.forEach(searchRecursive);
            }
        };

        items.forEach(searchRecursive);
        return matchingIds;
    };

    // Filtrage des résultats de recherche avec collecte des IDs
    const filteredData = useMemo(() => {
        if (!searchTerm) return nafHierarchy;

        const searchRecursive = (items) => {
            return items.reduce((acc, item) => {
                const matchesSearch =
                    item.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.code.toLowerCase().includes(searchTerm.toLowerCase());

                if (matchesSearch) {
                    acc.push(item);
                } else if (item.children) {
                    const matchingChildren = searchRecursive(item.children);
                    if (matchingChildren.length > 0) {
                        acc.push({
                            ...item,
                            children: matchingChildren
                        });
                    }
                }
                return acc;
            }, []);
        };

        return searchRecursive(nafHierarchy);
    }, [nafHierarchy, searchTerm]);

    // Mettre à jour les nœuds expandés lors d'une recherche
    useEffect(() => {
        if (searchTerm) {
            const matchingIds = collectMatchingNodeIds(nafHierarchy, searchTerm);
            setExpandedNodes(matchingIds);
        } else {
            setExpandedNodes(new Set());
        }
    }, [searchTerm, nafHierarchy]);

    // Gestion de l'expansion des nœuds
    const toggleNode = (id) => {
        setExpandedNodes(prev => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    // Gestion de la sélection
    const handleSelect = (code) => {
        setSelectedCode(code === selectedCode ? null : code);
    };

    // Rendu récursif des items avec mise en surbrillance de la recherche
    const renderNAFItem = (item, depth = 0) => {
        const isExpanded = expandedNodes.has(item.id);
        const isSelected = selectedCode === item.id;
        const hasChildren = item.children && item.children.length > 0;

        // Mise en surbrillance du texte recherché
        const highlightText = (text) => {
            if (!searchTerm) return text;
            const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
            return (
                <span>
                    {parts.map((part, i) =>
                        part.toLowerCase() === searchTerm.toLowerCase()
                            ? <span key={i} className="bg-yellow-200">{part}</span>
                            : part
                    )}
                </span>
            );
        };

        return (
            <div key={item.id} className="group">
                <div
                    className={`
                        flex items-center cursor-pointer p-1 
                        ${isSelected ? 'bg-opacity-90' : 'hover:bg-gray-50'}
                        transition-colors duration-150 ease-in-out
                    `}
                    style={{ paddingLeft: `${depth * 20}px` }}
                    onClick={() => !hasChildren && handleSelect(item.id)}
                >
                    {hasChildren && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleNode(item.id);
                            }}
                            className="px-2 mr-2 text-gray-400 hover:text-gray-600"
                        >
                            {isExpanded
                                ? <ChevronDown className="w-5 h-5" />
                                : <ChevronRight className="w-5 h-5" />
                            }
                        </button>
                    )}
                    <div className={`
                        flex-1 rounded-lg p-2
                        ${isSelected ? "bg-[#006acc] text-white" : "text-[#1e4690] hover:bg-gray-100"}
                        ${hasChildren ? "font-semibold" : ""}
                        transition-all duration-150
                    `}>
                        <span className="text-left">
                            {highlightText(item.code)} - {highlightText(item.label)}
                        </span>
                    </div>
                </div>

                {isExpanded && item.children && (
                    <div className="ml-4">
                        {item.children.map(child => renderNAFItem(child, depth + 1))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <div className='max-w-[800px] w-full -mt-2'>
                <h1 className='text-2xl text-[#1e4690] text-center my-3'>
                    Veuillez préciser l'activité réelle de votre entreprise :
                </h1>

                <div className="relative my-4 mt-8">
                    <input
                        type="text"
                        className="w-full p-3 pl-10 rounded-2xl border border-gray-200 focus:border-[#1e4690] focus:ring-1 focus:ring-[#1e4690] transition-all duration-200"
                        placeholder="TITRE OU MOTS-CLÉS DE L'ACTIVITÉ OU DU MÉTIER"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Search className="absolute left-3 top-3 text-[#1e4690] w-5 h-5" />
                </div>

                <div className="border-b border-[#1e4690] mb-4"></div>

                <div className="border rounded-lg my-2 overflow-auto h-96 bg-white shadow-sm">
                    {filteredData.map(item => renderNAFItem(item))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button
                    className="border border-[#006acc] text-[#006acc] px-12 py-3 rounded-full hover:bg-[#006acc] hover:text-white transition-all duration-300"
                    onClick={onBack}
                >
                    RETOUR
                </button>
                <button
                    onClick={() => selectedCode && onValidate(selectedCode)}
                    className={`
                        px-12 py-3 rounded-full transition-all duration-300
                        ${selectedCode
                            ? 'bg-[#1e4690] text-white hover:bg-[#1e469094]'
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'}
                    `}
                    disabled={!selectedCode}
                >
                    VALIDER
                </button>
            </div>
        </div>
    );
};

export default NAFCodeSelector;