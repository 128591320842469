import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const LocBiensImmoUsagePro = ({ isSCI, nbStep, locMeubleeNonMeubleeQ, nbCandtionMicroValide, locMeubleeOccasMoins10p, moveToStep,
    setLocBiensImmoUsagePro, locBiensImmoUsagePro,
    goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');

    const handleConfirm = () => {
        if (locMeubleeNonMeubleeQ.includes("NON-MEUBLEE") && locMeubleeOccasMoins10p) {
            moveToStep("impositionAssociesQ");
        } else {
            moveToStep("personnalDataSelection");
        }
    }

    const options = [
        { key: 'BUREAU_LIB', label: { fr: 'bureaux dédiés à une activité libérale', en: 'Dedicated offices for liberal activities' } },
        { key: 'IND_COMM_NONEQUIP', label: { fr: 'immeubles à usage industriel et commercial non équipés de moyens d’exploitation', en: 'Industrial and commercial buildings not equipped with operating facilities' } },
        { key: 'PARKING', label: { fr: 'emplacements de stationnement de véhicules (parking)', en: 'Vehicle parking spaces' } },
        { key: 'USAGE_COMMERCE', label: { fr: 'locaux à usage commercial lorsque le bailleur participe aux résultats de l’entreprise', en: 'Commercial premises when the landlord participates in business results' } },
        { key: 'NON_DEST_HABITATION3/4', label: { fr: 'immeubles construits par la SCI et n’étant pas destinés à l’habitation principale pour les 3/4 de sa superficie', en: 'Buildings constructed by the SCI not intended for primary residence for 3/4 of their area' } },
        { key: 'TERRAIN_NU', label: { fr: 'terrains nus', en: 'Vacant land' } },
        { key: 'BUREAU_COM', label: { fr: 'bureaux dédiés à une activité commerciale, artisanale ou industrielle', en: 'Offices dedicated to commercial, artisanal, or industrial activities' } },
        { key: '*AO', label: { fr: '- aucune de ces options -', en: '- none of these options -' } },
    ];

    // Fonction pour ajouter/retirer une option sélectionnée

    const toggleSelectedValue = (key) => {
        setLocBiensImmoUsagePro(prevState => {
            if (key === "*AO") {
                if (prevState.includes("*AO")) {
                    return [];
                }
                return ["*AO"];
            }
            if (prevState.includes("*AO")) {
                return [key];
            }

            if (prevState.includes(key)) {
                return prevState.filter(item => item !== key);
            }
            return [...prevState, key];
        });
    };


    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            La location à usage professionnel se fait pour le(s) bien(s) immobilier(s) suivant(s) :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            The following property(ies) are let for business use:
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="mb-4">
                    <input
                        type="text"
                        className="w-full p-3 border-2 border-gray-200 rounded-2xl"
                        placeholder={i18n.language === 'fr' ? "Titre ou mot-clé" : "Title or keyword"}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold rounded-xl ${locBiensImmoUsagePro.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(locBiensImmoUsagePro.length !== 0)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default LocBiensImmoUsagePro;
