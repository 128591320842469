import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const LocHabitPersoProprioQ = ({ nbStep, isSCI, nbCandtionMicroValide, locHabitPersoProprioQ, setLocHabitPersoProprioQ, moveToStep, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        moveToStep('assujettissementLoyerProComTvaQ');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);


    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            La location meublée (chambre, studio, appartement) est incluse dans l'habitation personnelle du propriétaire (résidence principale ou secondaire) et ne constituent pas l'habitation principale ou secondaire du locataire ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Is the furnished rental (room, studio, flat) included in the owner's personal residence (principal or secondary residence) and not the tenant's principal or secondary residence?                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${locHabitPersoProprioQ === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setLocHabitPersoProprioQ(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${locHabitPersoProprioQ === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setLocHabitPersoProprioQ(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(locHabitPersoProprioQ !== null)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default LocHabitPersoProprioQ;
