import React, { useEffect, useState } from "react";
import cat_1 from "../assets/experts/cat-1.svg";
import cat_2 from "../assets/experts/cat-2.svg";
import cat_3 from "../assets/experts/cat-3.svg";
import cat_4 from "../assets/experts/cat-4.svg";
import cat_5 from "../assets/experts/cat-5.svg";
import cat_6 from "../assets/experts/cat-6.svg";
import cat_7 from "../assets/experts/cat-7.svg";
import cat_8 from "../assets/experts/cat-8.svg";

import { useNavigate } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import { useTranslation } from "react-i18next";
import PartnerCard from "../components/PartnerCard";
import Footer from "./Footer";
import CategoryButtons from "./CategoryButtons";

const ExpertsPage = () => {
    let navigate = useNavigate();
    const [isScrolling, setIsScrolling] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 70) {
                setIsScrolling(true);
            } else {
                setIsScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Cette ligne scrolle la page en haut
    }, []);

    const partners = [
        {
            logo: "https://infogreffe.mesobligations.fr/repository/59e657827bd8cbdccad9ba1b7ada8484/Logo-AP.png",
            name: "Action Prévention",
            type_service: "Consultant",
            description: "Action Prévention est une plateforme entièrement dédiée à la prévention des entreprises en difficulté.Notre algorithme, conçu par des experts en la matière, permet de mieux outiller le chef d'entreprise en améliorant la détection et la prévention des difficultés.Même face à une situation obérée, de nombreux chefs d'entreprises hésitent encore à demander de l'aide faute d'informations sur les procédures existantes.Action Prévention est donc là pour tous ces chefs d'entreprise afin de leur proposer un diagnostic gratuit de leur situation et les aiguiller sur la solution juridique la plus adaptée à leurs besoins.Une fois ce diagnostic posé, celui-ci est expliqué afin d'apporter une vision claire et rassurante de la procédure par laquelle le dirigeant devra passer.Action Prévention met ensuite le dirigeant en relation avec des professionnels géographiquement proches de lui et spécialisés dans le traitement des difficultés des entreprises.",
            solutions: [{ name: 'Traitement des entreprises en difficulté', description: '' },
            { name: 'Procédure préventive', description: '' },
            { name: 'Procédure collective', description: '' }
            ]
        },
        {
            logo: "https://infogreffe.mesobligations.fr/repository/6f31cba19ab12f5ff9d6fe04e02f0f9b/atout-chimie.png",
            name: "ATOUT CHIMIE",
            type_service: "Cabinet d'audit et de conseil",
            description: "Intoxication règlementaire? Notre équipe d'experts vous accompagne dans la mise en conformité de vos produits chimiques",
            solutions: [{ name: 'Règlement REACH', description: '' },
            { name: 'Règlement des Produits biocides (BPR)', description: '' },
            { name: 'Classification, Etiquetage, Emballage (CLP) des substances et mélanges', description: '' },
            { name: 'Evaluation de risque environnemental des produits pharmaceutiques', description: '' }
            ]
        },
        {
            logo: "https://infogreffe.mesobligations.fr/repository/d2cf1280cc5b91dd7da998f0740eae9a/auxoAvocats3.jpg",
            name: "AUXO AVOCAT",
            type_service: "Avocat",
            description: "Le Cabinet AUXÔ AVOCATS réunit trois avocats en droit des affaires, experts dans le domaine du conseil aux entreprises. Les membres d'AUXÔ AVOCATS interviennent auprès des entreprises de tous secteurs d'activités et de toutes tailles : Start-up, sociétés d'investissements, PME, ETI, grands groupes, commerçants, professions libérale dans les domaines suivants :&nbsp;Corporate/Fusion acquisition&nbsp; Droit des sociétés et gouvernance&nbsp;Fiscalité des entreprises - restructurations - du patrimoine et contentieuxManagement packages&nbsp;Pacte Associés&nbsp;Start - up et capital risque&nbsp;Procédures collectives",
            solutions: [{ name: 'Droit des Affaires', description: '' },
            { name: 'Droit des sociétés et gouvernance', description: '' },
            { name: 'Droit fiscal', description: '' },
            { name: 'Contentieux', description: '' },
            ]
        },
        {
            logo: "https://infogreffe.mesobligations.fr/repository/c15e5c6244a9f62a80f912ee9ec611f1/axiocapvD.png",
            name: "Axiocap",
            type_service: "Société de services",
            description: "Gagnez en efficacité et en sécurité tout en allégeant vos processus papier lourds et chronophages ! Numéro 1 des solutions digitales dédiées à la gestion dématérialisée de l'actionnariat, Axiocap a été développé pour les entreprises non cotées et multi-actionnaires, ainsi que les professionnels du droit et du chiffre.",
            solutions: [{ name: 'Gestion des titres et parts sociales en ligne', description: '' },
            { name: 'Registre des mouvements de titres en ligne', description: '' },
            { name: 'Registres légaux obligatoires', description: '' },
            { name: "Registres d'entreprise dématérialisés", description: '' },
            { name: 'Gestion des assemblées et conseils en ligne', description: '' },
            { name: 'Coffre-fort numérique (CCFN)', description: '' },
            { name: 'Signature électronique légale', description: '' },
            { name: 'Blockchain', description: '' },
            ]
        },
        {
            logo: "https://infogreffe.mesobligations.fr/repository/13549fcc3c9519915acf227bb2833c28/axonaut-bleu-vertical.svg",
            name: "Axonaut",
            type_service: "Société de services",
            description: "Axonaut est la solution française qui aide les entrepreneurs et chefs d'entreprise dans toutes les tâches administratives et commerciales.A l'aide d'un logiciel simple & abordable, ils automatisent :La gestion commercialeL'édition des Devis & FacturesLe suivi de la TrésorerieLes relations avec le comptable grâce à un Portail dédiéet aussi les RH, les projets, le marketing et les stocks",
            solutions: [{ name: 'Gestion commerciale (CRM)', description: '' },
            { name: 'Edition des Devis & Factures électroniques', description: '' },
            { name: 'Rapprochement bancaire', description: '' },
            { name: 'Suivi de la Trésorerie', description: '' }
            ]
        },
        // {
        //     logo: "https://infogreffe.mesobligations.fr/repository/59e657827bd8cbdccad9ba1b7ada8484/Logo-AP.png",
        //     name: "Action Prévention",
        //     type_service: "Consultant",
        //     description: "Action Prévention est une plateforme entièrement dédiée à la prévention des entreprises en difficulté.Notre algorithme, conçu par des experts en la matière, permet de mieux outiller le chef d'entreprise en améliorant la détection et la prévention des difficultés.Même face à une situation obérée, de nombreux chefs d'entreprises hésitent encore à demander de l'aide faute d'informations sur les procédures existantes.Action Prévention est donc là pour tous ces chefs d'entreprise afin de leur proposer un diagnostic gratuit de leur situation et les aiguiller sur la solution juridique la plus adaptée à leurs besoins.Une fois ce diagnostic posé, celui-ci est expliqué afin d'apporter une vision claire et rassurante de la procédure par laquelle le dirigeant devra passer.Action Prévention met ensuite le dirigeant en relation avec des professionnels géographiquement proches de lui et spécialisés dans le traitement des difficultés des entreprises.",
        //     solutions: [{ name: 'Traitement des entreprises en difficulté', description: '' },
        //     { name: 'Procédure préventive', description: '' },
        //     { name: 'Procédure collective', description: '' }
        //     ]
        // },
    ];

    return (
        <div className={`flex flex-col  ${isScrolling ? 'scrolling' : ''}`}>
            <NavigationBar isPageExpert={true} />

            {/* Main Content */}
            <div className={`section__area blog__hero bg__azure hero__bg one pt-0 overflow-visible`}>
                <div className="text-light p-4 sm:p-6 md:p-8 lg:p-12">
                    <h1 className="title text-5xl mt-4 mb-2 text-light font-bold" data-translate="">
                        <span className="color__green">Nos experts</span> partenaires
                    </h1>
                    <h3 data-translate="titre-les-experts font-bold" className="text-xl">Nous avons <span className="color__green">une communauté d'expert</span> qui <span className="color__green">accompagne </span> les entreprises <p> <span className="color__green"> dans la conformité en proposant des solutions d’accompagnement</span></p>
                    </h3>
                </div>
            </div>
            <div className={`one overflow-visible px-4 sm:px-6 md:px-8 lg:px-12`}>
                <CategoryButtons />
            </div>
            <div className="mx-auto mt-2 p-4 sm:px-6 md:px-8 lg:px-12 relative bottom-20">
                <h1 className="text-[#1e4690] font-bold text-3xl mb-6">Liste des experts partenaires</h1>
                <p className="text-[#1e4690] mb-10 ">
                    Les experts sont affichés par ordre alphabétique, de gauche à droite. Cliquez
                    <a href="#"> ici</a> pour en savoir plus sur nos modalités de référencement.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {partners.map((partner, index) => (
                        <div
                            key={`${partner.name}-${index}`}
                            className="w-full"
                        >
                            <PartnerCard partner={partner} />
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </div >
    );
};



export default ExpertsPage;
