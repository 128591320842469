import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { useNavigate } from 'react-router-dom';
import { getEnvConfig } from '../../utils/config';
import { themePopOver, updateProgressColor } from '../../utils/utils';
import { Popover } from 'flowbite-react';
import ContinueButton from './ContinueButton';

const PersonnalDataSelector = ({
  nbStep, moveToStep, goBack,
  raisonSociale,
  isMicroEntreprise,
  nbCandtionMicroValide,
  traitementDonneesPerso,
  isComanyCommercial,
  isSCI,
  setTraitementDonneesPerso,
}) => {
  const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);

  const rangeRef = useRef(null);
  const { t, i18n } = useTranslation();

  const handleConfirm = async () => {
    if (traitementDonneesPerso) {
      moveToStep('DataSubContractor');
    } else {
      if (isComanyCommercial) {
        moveToStep('DetentionSiteInternet');
      } else {
        moveToStep('eprSelection');
      }
    }
  };

  useEffect(() => {
    updateProgressColor(nbStep, maxValueQuestion, rangeRef);
  }, []);

  return (
    <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
      <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


      <div className='max-w-[800px] mb-1'>

        <h1 className='text-2xl text-center text-[#1e4690] my-6'>
          {i18n.language === 'fr' &&
            <>
              {raisonSociale} traite des {' '}
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>
                        Une donnée personnelle est toute information se rapportant à une personne physique identifiée ou identifiable. Mais, parce qu'elles concernent des personnes, celles-ci doivent en conserver la maîtrise.
                      </p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  données à caractère personnel
                </a>
              </Popover>, pour son compte ou celui d'une autre entité ?
            </>
          }

          {i18n.language === 'en' &&

            <>
              The company {raisonSociale} processes {" "}
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>
                        Personal data is any information relating to an identified or identifiable natural person. But because it concerns individuals, they must retain control of it.                      </p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  personal data
                </a>
              </Popover>, on its own behalf or not?
            </>
          }

        </h1>
      </div>

      <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
        <div className="flex flex-col gap-4">
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${traitementDonneesPerso === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
            onClick={() => setTraitementDonneesPerso(true)}
          >
            {i18n.language === 'fr' ? "Oui" : "Yes"}
          </button>
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${traitementDonneesPerso === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
            onClick={() => setTraitementDonneesPerso(false)}
          >
            {i18n.language === 'fr' ? "Non" : "No"}
          </button>
        </div>
      </div>

      <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
        <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
          {t('revenu.button_back')}
        </button>
        <ContinueButton
          isValid={traitementDonneesPerso !== null && traitementDonneesPerso !== ''}
          onConfirm={handleConfirm}
        />
      </div>
    </div>
  );
};

export default PersonnalDataSelector;
