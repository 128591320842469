import React, { useContext } from 'react';
import { Dropdown, DropdownHeader, DropdownItem, DropdownDivider, Avatar } from "flowbite-react";
import { useAppContext } from '../context/AppContext';
import { AuthContext } from '../context/AuthContext';
import { HiLogout } from "react-icons/hi";
import { useTranslation } from 'react-i18next';
import { getEnvConfig } from '../utils/config';

const UserDropdownMenu = ({ handleContactClick }) => {

    const { handleLogout } = useContext(AuthContext);
    const { currentUser, userData } = useAppContext();
    const { t } = useTranslation();


    const Logout = () => {
        handleLogout()
        localStorage.removeItem('questionnaireState');
        localStorage.removeItem('result');
        localStorage.removeItem('raisonSociale');
    }
    console.log("userData :", userData)

    const handleClick = async () => {
        try {
            const user = currentUser;
            const idToken = await user.getIdToken();
            const { apiUrl } = getEnvConfig();
            const response = await fetch(apiUrl + "create-customer-portal-session", {
                method: "POST",
                // const response = await fetch(apiUrl + "get-invoices", {
                // method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${idToken}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Erreur lors de la création de la session du portail client');
            }

            const data = await response.json();
            console.log(data)
            window.location.href = data.url;
        } catch (error) {
            console.error('Erreur lors de la création de la session du portail client:', error);
        }
    };

    return (
        <Dropdown
            label={<Avatar alt="User settings" img={currentUser?.photoURL || null} rounded />}
            arrowIcon={false}
            inline
        >
            <DropdownHeader>
                <span className="block text-sm">{currentUser.displayName}</span>
                <span className="block truncate text-sm font-medium">{currentUser.email}</span>
            </DropdownHeader>
            {userData && userData.remaining_reports > 0 ? (
                <>
                    <DropdownItem>{t('offre')}</DropdownItem>
                    <DropdownItem onClick={handleClick} >{t('d_facutre')}</DropdownItem>
                    <DropdownItem>{t('nb_reports')} <b>{userData.remaining_reports}</b></DropdownItem>
                </>
            ) : (
                <DropdownItem onClick={handleContactClick}> {t('souscribe')}</DropdownItem>
            )}
            <DropdownDivider />
            <DropdownItem icon={HiLogout} onClick={Logout}> {t('sign_out')} </DropdownItem>
        </Dropdown>
    );
};

export default UserDropdownMenu;