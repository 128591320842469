import React from 'react';
import cat_1 from "../assets/experts/cat-1.svg";
import cat_2 from "../assets/experts/cat-2.svg";
import cat_3 from "../assets/experts/cat-3.svg";
import cat_4 from "../assets/experts/cat-4.svg";
import cat_5 from "../assets/experts/cat-5.svg";
import cat_6 from "../assets/experts/cat-6.svg";
import cat_7 from "../assets/experts/cat-7.svg";
import cat_8 from "../assets/experts/cat-8.svg";

const CategoryButtons = () => {
    const categories = [
        { name: "Fiscal", icon: cat_1 },
        { name: "Social", icon: cat_2 },
        { name: "Gestion Comptabilité", icon: cat_3 },
        { name: "Environnement", icon: cat_4 },
        { name: "Gouvernance & Engagement", icon: cat_5 },
        { name: "Compliance", icon: cat_6 },
        { name: "Ventes Commerce", icon: cat_7 },
        { name: "Informatique Technologies", icon: cat_8 },
    ];

    return (
        <div className="bg-blue-200 p-4 text-[#1e4690] relative rounded-3xl bottom-14 md:bottom-28">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {categories.map((category, index) => (
                    <button
                        key={index}
                        className="bg-white rounded-full px-4 py-3 hover:bg-[#f0f0f0] flex items-center justify-between shadow-lg w-full"
                    >
                        <span className="font-bold text-sm md:text-base text-start">{category.name}</span>
                        <img src={category.icon} alt={category.name} className="h-8 w-8 md:h-10 md:w-10" />
                    </button>
                ))}
            </div>
        </div>
    );
};

export default CategoryButtons;