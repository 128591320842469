import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';

import { useNavigate } from 'react-router-dom';
import ContinueButton from './ContinueButton';

const SurfaceExploitationSup1000m2 = ({ isSCI, proprioBatimentImmoQ, nbCandtionMicroValide, locMeubleeNonMeubleeQ, locMeubleeOccasMoins10p, nbStep,
    moveToStep, goBack, raisonSociale, isMicroEntreprise, surfaceExploitationSup1000mC, setSurfaceExploitationSup1000mC }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();


    const handleConfirm = () => {
        if (proprioBatimentImmoQ.some(value => value.startsWith('PRO'))) {
            moveToStep('locBiensImmoUsagePro');
        } else if (locMeubleeNonMeubleeQ.includes("NON-MEUBLEE") && locMeubleeOccasMoins10p) {
            moveToStep("impositionAssociesQ");
        } else {
            moveToStep("personnalDataSelection");
        }
        // // TODO: prepare Json data to send to API
        // console.log("prepare Json data to send to API");
        // navigate('/workspace');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);





    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} possède des bâtiments ou locaux à usage tertiaire dont la surface de plancher est supérieure ou égale à 1 000 m2 ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} owns buildings or premises for tertiary use with a floor area of 1,000 m2 or more?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${surfaceExploitationSup1000mC === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setSurfaceExploitationSup1000mC(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${surfaceExploitationSup1000mC === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setSurfaceExploitationSup1000mC(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(surfaceExploitationSup1000mC !== null)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default SurfaceExploitationSup1000m2;
