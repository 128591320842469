import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { themePopOver, updateProgressColor } from '../../utils/utils';
import { Popover } from 'flowbite-react';
import ContinueButton from './ContinueButton';

const RegimeTva = ({ nbStep, isSCI, isComanyCommercial, catJuridiqueNiv3, nbCandtionMicroValide, moveToStep, goBack, raisonSociale, regimeTva, isMicroEntreprise, setRegimeTva }) => {
  const [value, setValue] = useState(regimeTva);
  const rangeRef = useRef(null);
  const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
  const { t, i18n } = useTranslation();


  const handleConfirm = () => {
    console.log("isMicroEntreprise:", isMicroEntreprise);
    console.log("isSCI:", isSCI);

    setRegimeTva(value);
    if (isSCI) {
      moveToStep('optionImpotSocieteISQ');
    } else if (isMicroEntreprise) {
      moveToStep('optionRegimeMicroEntrepriseQ')
    } else if (isComanyCommercial) {
      moveToStep('SujecteToTva')
    } else {
      alert(`Le questionnaire ne prend pas encore en compte le type de société spécifique. Actuellement, nous ne pouvons traiter que les SCI, Micro entreprises, et entreprises commerciales. Vous avez sélectionné : ${catJuridiqueNiv3}`)
    }
  };

  useEffect(() => {
    updateProgressColor(nbStep, maxValueQuestion, rangeRef);
  }, []);


  return (
    <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
      <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


      <div className='max-w-[800px] -mt-2'>

        <h1 className='text-2xl text-[#1e4690] my-3'>
          {i18n.language === 'fr' &&
            <>
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>
                        Les personnes physiques ou morales qui exercent de manière indépendante une activité économique à titre habituel sont assujetties à la TVA. Cette activité doit comporter une contrepartie directe : il s'agit de la lucrativité recherchée de l'opération.
                      </p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  Régime d'imposition de la TVA
                </a>
              </Popover>{' '}
              de {raisonSociale} :
            </>
          }

          {i18n.language === 'en' &&
            <>
              {raisonSociale}{" "}
              <>
                <Popover theme={themePopOver}
                  trigger="hover"
                  aria-labelledby="default-popover"
                  content={
                    <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                      <div className="px-3 py-2">
                        <p>
                          Natural or legal persons who independently carry out an economic activity on a regular basis are subject to VAT. This activity must involve a direct consideration: this is the intended profit-making nature of the transaction.
                        </p>
                      </div>
                    </div>
                  }
                >
                  <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                    Corporate VAT regime:
                  </a>
                </Popover>
              </>
            </>
          }

        </h1>
      </div>

      <div className='w-full max-w-[350px] p-4 rounded-3xl'>
        <div className="flex flex-col gap-4">
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${value === 'FRANCHISE' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]  hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]'}`}
            onClick={() => setValue('FRANCHISE')}
          >
            {i18n.language === 'fr' ? "Franchise en base de TVA" : "VAT basis franchise"}
          </button>
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${value === 'RS' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc]  hover:border hover:text-[#006acc]'}`}
            onClick={() => setValue('RS')}
          >
            {i18n.language === 'fr' ? "Réel simplifié" : "Simplified"}
          </button>
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${value === 'RN' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc]  hover:border hover:text-[#006acc]'}`}
            onClick={() => setValue('RN')}
          >
            {i18n.language === 'fr' ? "Réel normal" : "Normal"}
          </button>
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${value === 'NON_ASSUJETTI' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc]  hover:border hover:text-[#006acc]'}`}
            onClick={() => setValue('NON_ASSUJETTI')}
          >
            {i18n.language === 'fr' ? "Non-assujetti à la TVA" : "Not subject to VAT"}
          </button>
        </div>
      </div>

      <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
        <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
          {t('revenu.button_back')}
        </button>
        <ContinueButton
          isValid={(value !== null)}
          onConfirm={handleConfirm}
        // isLastQst={isMicroEntreprise}
        />
      </div>
    </div >
  );
};

export default RegimeTva;
