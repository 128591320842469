import { useNavigate } from "react-router-dom";
import logo_legaware from "../assets/Legaware_logo_white.png"
import { useEffect } from "react";

// const ScrollToTop = () => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, [window.location.pathname]);

//     return null;
// };

const Footer = () => {

    let navigate = useNavigate();
    return (
        <>
            {/* <ScrollToTop /> */}
            <footer className="bg-[#1E4690] text-white py-8 rounded-ss-3xl xl:px-8 rounded-se-3xl">
                <div className="container mx-auto px-8">
                    <div className="flex flex-col md:flex-row justify-between items-start">
                        {/* Left side */}
                        <div className="mb-6 md:mb-0">
                            <p className="text-lg">Un service proposé par</p>
                            <img src={logo_legaware} className="h-10" />
                            <nav className="mt-4">
                                <ul className="flex flex-col space-y-2">
                                    {[
                                        { id: '1', path: '/mentionslegales', label: 'Mentions légales' },
                                        { id: '2', path: '/politique-confidentialite', label: 'Politique de confidentialité' },
                                        { id: '3', path: '/qualite-personnes-autorisees-a-deposer-une-offre', label: 'Qualité des personnes à déposer une offre' },
                                        { id: '4', path: '/conditions-generales', label: 'Conditions générales de ventes et d\'utilisation' },
                                        { id: '5', path: '/modalites-referencement', label: 'Modalités de référencement, déréférencement et de classement' },
                                        { id: '6', path: '/regles-recommandations-redaction-offre-contenu', label: 'Règles et recommandations concernant la rédaction d’une offre et d’un contenu' }
                                    ].map((item) => (
                                        <li key={item.id}>
                                            <button
                                                onClick={() => {
                                                    localStorage.setItem('navigatePath', item.path);
                                                    navigate(item.path);
                                                    window.scrollTo(0, 0);
                                                }}
                                                onKeyDown={(e) => { if (e.key === 'Enter') navigate(item.path); }}
                                                tabIndex="0"
                                                className="text-sm hover:underline"
                                                aria-pressed="false"
                                            >
                                                {item.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold mb-4 cursor-pointer hover:underline" onClick={() => navigate('/experts')} >Services Partenaires</h3>
                            <ul className="space-y-2">
                                <li className="text-sm">
                                    <a href="https://oliver.legal/" target="_blank" rel="noreferrer" className="text-sm hover:underline" > Oliver - Votre assistant juridique boosté à l'IA</a>
                                </li>
                                <li className="text-sm">
                                    <a href="https://mesformalites.legal/" target="_blank" rel="noreferrer" className="text-sm hover:underline" > MesFormalités.legal - Le guichet qui vous accompagne dans vos formalités juridiques</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;