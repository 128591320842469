import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';

const DateClosed = ({
    nbStep,
    isSCI,
    moveToStep,
    goBack,
    nbCandtionMicroValide,
    datePremClotureX,
    setDatePremClotureX,
    raisonSociale,
    isMicroEntreprise
}) => {
    const rangeRef = useRef(null);
    const { t, i18n } = useTranslation();

    // Calculer le nombre maximum de questions
    const maxValueQuestion = useMemo(() => {
        if (isMicroEntreprise) return Math.max(nbStep + 1, 9);
        if (isSCI) return Math.max(nbStep + 1, 18);
        return Math.max(nbStep + 1, 23);
    }, [isMicroEntreprise, isSCI, nbStep]);

    // Définir la date de clôture par défaut (31/12 de l'année précédente)
    useEffect(() => {
        if (!datePremClotureX) {
            const currentYear = new Date().getFullYear();
            const defaultDate = `${currentYear - 1}-12-31`; // Format YYYY-MM-DD pour l'input type="date"
            setDatePremClotureX(defaultDate);
        }
    }, []);

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, [nbStep, maxValueQuestion]);

    const handleConfirm = () => {
        moveToStep('totalBilanQ');
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value || `${new Date().getFullYear() - 1}-12-31`;
        setDatePremClotureX(newDate);
    };

    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar
                value={nbStep}
                maxValueQuestion={maxValueQuestion}
                rangeRef={rangeRef}
            />

            <div className='max-w-[800px] mb-4 mt-6'>
                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {t('date_closed.title', {
                        defaultValue: i18n.language === 'fr'
                            ? "Dernière date de clôture de l'entreprise :"
                            : "Last company closing date:"
                    })}
                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <input
                    type="date"
                    className="w-full p-4 text-center font-bold rounded-xl bg-white text-[#006acc]"
                    value={datePremClotureX || ''} // Utiliser une chaîne vide si null
                    onChange={handleDateChange}
                    max={new Date().toISOString().split('T')[0]} // Empêcher les dates futures
                />
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button
                    className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300"
                    onClick={goBack}
                >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={Boolean(datePremClotureX)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default DateClosed;