import { useState } from "react";

const PartnerCard = ({ partner, isHomePage = true }) => {
    const [showAllSolutions, setShowAllSolutions] = useState(false);

    return (
        <div className="rounded-xl overflow-hidden border max-w-[350px] hover:shadow-2xl transition-shadow relative">
            <div className="partenaire__image">
                <img className="h-[210px]" src={partner.logo} alt={partner.name} />
            </div>
            <div
                className="px-6 pt-4 pb-2 flex items-start relative z-0"
                onMouseEnter={() => setShowAllSolutions(true)}
                onMouseLeave={() => setShowAllSolutions(false)}
                style={{ maxHeight: '206px' }}
            >
                <span className="inline-block bg-white text-left border border-[#112879] rounded-full px-3 py-1 text-sm text-[#112879] mr-2 mb-2">
                    {partner.solutions[0].name}
                </span>
                {showAllSolutions && (

                    <div className="absolute inset-x-0 top-0 bg-gradient-to-b from-white to-[#ffffff53] p-4 overflow-y-auto z-10" style={{ maxHeight: '206px' }} >
                        {partner.solutions.map((solution, index) => (
                            <span key={`${solution.name}-${index}`} className="block bg-white text-left  border border-[#112879] rounded-full px-3 py-1 text-sm text-[#112879] mr-2 mb-2">
                                {solution.name}
                            </span>
                        ))}
                    </div>
                )}
            </div>
            <div className="px-6 pb-4 z-0">
                <div className="text-2xl text-[#112879] text-left mb-2">{partner.name}</div>
                <div className="text-xl text-[#0F6ACC] text-left  mb-2">{partner.type_service}</div>
                <div className="text-gray-700 text-base line-clamp-3 text-start mb-4" title={partner.description}>{partner.description}</div>
                {isHomePage &&
                    <button className="w-full px-6 py-2 text-[#0d6efd] border border-[#0d6efd] rounded-full bg-white hover:bg-[#0d6efd] hover:text-white">
                        Accéder à la vitrine
                    </button>
                }
            </div>
        </div>
    );
};

export default PartnerCard;

// import { useState } from "react";

// const PartnerCard = ({ partner }) => {
//     const [showAllSolutions, setShowAllSolutions] = useState(false);

//     return (
//         <div className="rounded-xl overflow-hidden border max-w-[350px] hover:shadow-2xl transition-shadow relative">
//             <div className="partenaire__image">
//                 <img className="h-[210px]" src={partner.logo} alt={partner.name} />
//             </div>
//             <div
//                 className="px-6 pt-4 pb-2 relative z-0"
//                 onMouseEnter={() => setShowAllSolutions(true)}
//                 onMouseLeave={() => setShowAllSolutions(false)}
//             >
//                 <span className="inline-block bg-white border border-[#112879] rounded-full px-3 py-1 text-sm text-[#112879] mr-2 mb-2">
//                     {partner.solutions[0].name}
//                 </span>
//                 {showAllSolutions && (
//                     <div className="absolute inset-x-0 top-0 bg-white border-t-2 border-[#112879] p-4 overflow-y-auto z-10" style={{ maxHeight: '206px' }}>
//                         {partner.solutions.map((solution, index) => (
//                             <span key={`${solution.name}-${index}`} className="block bg-white px-3 py-1 text-sm text-[#112879] mr-2 mb-2 hover:bg-[#f0f0f0] cursor-pointer">
//                                 {solution.name}
//                             </span>
//                         ))}
//                     </div>
//                 )}
//             </div>
//             <div className="px-6 py-4 z-0">
//                 <div className="text-2xl text-[#112879] mb-2">{partner.name}</div>
//                 <div className="text-xl text-[#0F6ACC] mb-2">{partner.type_service}</div>
//                 <div className="text-gray-700 text-base line-clamp-3 text-start mb-4" title={partner.description}>{partner.description}</div>
//                 <button className="w-full px-6 py-2 text-[#0d6efd] border border-[#0d6efd] rounded-full bg-white hover:bg-[#0d6efd] hover:text-white">
//                     Accéder à la vitrine
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default PartnerCard;

