import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc'; // Assurez-vous d'installer react-icons
import NavigationBar from './NavigationBar';
import {
    signOut,
    createUserWithEmailAndPassword,
    signInWithPopup,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    updateProfile,
} from "firebase/auth";

import { auth, goggleAuthProvider } from "../firebase.config";
import { fetchData } from '../utils/ask_api';
import { useNavigate } from 'react-router-dom';
import { IoIosEye, IoMdEyeOff } from "react-icons/io";

const LoginComponent = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState("login");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptCgus, setAcceptCgus] = useState(false);
    const [acceptCodedConsomation, setAcceptCodedConsomation] = useState(false);
    const [isFirstNameValid, setIsFirstNameValid] = useState(true);
    const [isLastNameValid, setIsLastNameValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    };

    // Update event handlers
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(validateEmail(emailValue));
    };

    const handleFirstNameChange = (e) => {
        const firstNameValue = e.target.value.trim();
        setFirstName(firstNameValue);
        setIsFirstNameValid(firstNameValue.length > 0);
    };

    const handleLastNameChange = (e) => {
        const lastNameValue = e.target.value.trim();
        setLastName(lastNameValue);
        setIsLastNameValid(lastNameValue.length > 0);
    };

    const resetForm = () => {
        setEmail("");
        setPassword("");
        // setLastName("");
        // setConfirmPassword("");
        // setIsFirstNameValid(true);
        // setIsLastNameValid(true);
        // setIsEmailValid(true);
        // setErrorMessage("");
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            let userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            resetForm();
            const redirectPath = sessionStorage.getItem('redirectPath') || '/';
            sessionStorage.removeItem('redirectPath');
            navigate(redirectPath);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrorMessage(errorMessage);
        }
    };

    const handleSignInWithGoggle = async () => {
        try {
            let userCredential = await signInWithPopup(auth, goggleAuthProvider);
            // once user is signed in navigate them to the home page
            console.log(userCredential);
            if (userCredential._tokenResponse.isNewUser) {
                const d = await fetchData("add_user", null, "POST");
            }
            resetForm();
            const redirectPath = sessionStorage.getItem('redirectPath') || '/';
            sessionStorage.removeItem('redirectPath');
            navigate(redirectPath);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrorMessage(errorMessage);
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        // Check if passwords match
        if (password !== confirmPassword) {
            setErrorMessage("Les mots de passes ne correspondent pas.");
            return;
        }

        try {
            let userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            console.log(userCredential);
            await updateProfile(userCredential.user, {
                displayName: `${firstName} ${lastName}`,
            });
            await sendEmailVerification(userCredential.user);
            const d = await fetchData("add_user", null, "POST");
            // await signOut(auth);
            // resetForm();
            navigate("/waiting-for-confirmation");
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrorMessage(errorMessage);
        }
    };

    const handleForgotPassword = async () => {
        // Check if email is empty
        if (email.trim() === "") {
            setErrorMessage("Veuillez entrer votre adresse e-mail.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setErrorMessage("Un email contenant un lien vous a été envoyé.");
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrorMessage(
                "Une erreur est survenue, veuillez entrer un email associé à un compte."
            );
        }
    };


    return (
        <div className={`flex flex-col  pt-[72px] min-h-screen bg-gray-100`}>
            <NavigationBar background={'gray-100'} isQuestionnaire={true} />
            <div className="w-96 md:w-1/3 mx-auto mt-6 p-6 rounded-lg">
                {action === "login" ?
                    <>
                        <h1 className="text-xl font-bold mb-6 text-start text-[#1E4690]">
                            {t('connexionEspaceInfogreffe')}
                        </h1>

                        <button onClick={() => setAction("signup")} className="w-full bg-[#71E050] text-white font-bold py-3 px-4 rounded-md mb-6 hover:bg-[#5DC943] transition duration-300">
                            {t('creerCompteEmail')}
                        </button>
                    </> :
                    <>
                        <button onClick={() => setAction("login")} className="w-full text-[#1E4690] mb-2 font-bold px-4 rounded-md underline transition duration-300">
                            {t('retour')}
                        </button>
                        <h1 className="text-xl font-bold mb-2 text-start text-[#1E4690]">
                            {t('Creationdevotrecompte')}
                        </h1>
                        <p className="mb-6 text-start text-[#1E4690]">{t('messageCheckSpam')}</p>
                    </>

                }

                <form onSubmit={action === "login" ? handleLogin : handleSignup}>
                    {action === "signup" && (
                        <>
                            <div className="mb-4">
                                <label htmlFor="firstName" className="block mb-2 font-medium text-[#1E4690]">
                                    {t('firstName')}
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className="w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2988d6]"
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                    required
                                    style={{ borderColor: isFirstNameValid ? "" : "red" }}
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="lastName" className="block mb-2 font-medium text-[#1E4690]">
                                    {t('lastName')}
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className="w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2988d6]"
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                    required
                                    style={{ borderColor: isLastNameValid ? "" : "red" }}
                                />
                            </div>
                        </>
                    )}
                    <div className="mb-4">
                        <label htmlFor="email" className="block mb-2 font-medium text-[#1E4690]">
                            {t('email')}
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2988d6]"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            style={{ borderColor: isEmailValid ? "" : "red" }}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="password" className="block mb-2 font-medium text-[#1E4690]">
                            {t('password')}
                        </label>
                        <div className="relative w-full h-full">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                id="password"
                                className="w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2988d6]"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div className="absolute top-0 right-0 h-full w-12 flex items-center justify-center">
                                {passwordVisible ? (
                                    <IoMdEyeOff
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        className="w-full h-1/2 cursor-pointer"
                                    />
                                ) : (
                                    <IoIosEye
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        className="w-full h-1/2 cursor-pointer"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {action === "login" &&
                        <div className='mb-6'>

                            <button
                                onClick={handleForgotPassword}
                                className={`text-[#1E4690] underline font-semibold mt-2`}
                            >
                                {t('forgotyourpassword')}
                            </button>
                        </div>
                    }
                    {action === "signup" && (
                        <div className="mb-6">
                            <label htmlFor="password" className="block mb-2 font-medium text-[#1E4690]">
                                {t('verifierPassword')}
                            </label>
                            <div className="relative w-full h-full">
                                <input
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    className="w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2988d6]"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <div className="absolute top-0 right-0 h-full w-12 flex items-center justify-center">
                                    {confirmPasswordVisible ? (
                                        <IoMdEyeOff
                                            onClick={() =>
                                                setConfirmPasswordVisible(!confirmPasswordVisible)
                                            }
                                            className="w-full h-1/2 cursor-pointer"
                                        />
                                    ) : (
                                        <IoIosEye
                                            onClick={() =>
                                                setConfirmPasswordVisible(!confirmPasswordVisible)
                                            }
                                            className="w-full h-1/2 cursor-pointer"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {action === "signup" && (
                        <div className="mb-6">
                            <div className="text-sm text-justify">
                                <label>
                                    <input
                                        type="checkbox"
                                        id="termsCheckbox"
                                        checked={acceptTerms}
                                        onChange={(e) => setAcceptTerms(e.target.checked)}
                                        style={{ marginRight: "5px" }}
                                    />
                                    {t('acceptTerms')}
                                    <span className="text-blue-500 underline cursor-pointer">{t('privacypolicy')}</span>{" "}
                                </label>
                            </div>
                            <div className="text-sm text-justify">
                                <input
                                    type="checkbox"
                                    id="cgusCheckbox"
                                    className="mr-2"
                                    checked={acceptCgus}
                                    onChange={(e) => setAcceptCgus(e.target.checked)}
                                />
                                <label htmlFor="cgusCheckbox">
                                    {t('acceptCgus_1')}
                                    {" "}
                                    <span className="text-blue-500 underline cursor-pointer">
                                        {t('acceptCgus')}
                                    </span> {" "}
                                    {t('acceptCgus_2')}

                                </label>
                            </div>
                            <div className="text-sm text-justify">
                                <input
                                    type="checkbox"
                                    id="cgusCheckbox"
                                    className="mr-2"
                                    checked={acceptCodedConsomation}
                                    onChange={(e) => setAcceptCodedConsomation(e.target.checked)}
                                />
                                <label htmlFor="cgusCheckbox">
                                    {t('acceptCodedConsomation_1')}
                                    {" "}
                                    <span className="text-blue-500 underline cursor-pointer">
                                        {t('acceptCodedConsomation')}
                                    </span> {" "}

                                </label>
                            </div>
                        </div>
                    )}
                    <button
                        type="submit"
                        className="w-full bg-[#1E4690] text-white font-bold py-3 px-4 rounded-md hover:bg-[#163A7A] transition duration-300"
                    >
                        {action === "login" ? t('seConnecter') : t('revenu.button_save')}
                    </button>
                </form>
                {action === "login" &&
                    <>
                        <button onClick={handleSignInWithGoggle} className="w-full mt-4 bg-white text-[#1E4690] font-bold py-3 px-4 rounded-md border border-[#1E4690] hover:bg-gray-100 transition duration-300 flex items-center justify-center">
                            <FcGoogle className="mr-2" size={24} />
                            {t('seConnecterAvecGoogle')}
                        </button>
                    </>
                }
                {errorMessage.trim() !== " " && (
                    <div className="error_message mt-4">{errorMessage}</div>
                )}
            </div>
        </div>
    );
};

export default LoginComponent;