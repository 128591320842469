import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const ProdDetenCollecteEliminDechetsQ = ({ isSCI, nbStep, nbCandtionMicroValide, moveToStep, goBack, raisonSociale, isMicroEntreprise, NAFgroupe, prodDetenCollecteEliminDechetsQ, setProdDetenCollecteEliminDechetsQ }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');

    const handleConfirm = () => {
        moveToStep('eprSelection')
    }

    const toggleSelectedValue = (key) => {
        if (prodDetenCollecteEliminDechetsQ.includes(key)) {
            setProdDetenCollecteEliminDechetsQ(prodDetenCollecteEliminDechetsQ.filter((item) => item !== key));
        } else {
            if (prodDetenCollecteEliminDechetsQ.length < 5) {
                setProdDetenCollecteEliminDechetsQ([...prodDetenCollecteEliminDechetsQ, key]);
            } else {
                alert("Vous avez déjà sélectionné 5 éléments.");
            }
        }
    };



    const options = [
        {
            "key": "DASRI",
            "label": {
                "fr": "Déchets d'activités de soins à risques infectieux (DASRI) et assimilés",
                "en": "Infectious healthcare waste and related"
            }
        },
        {
            "key": "RESCARG",
            "label": {
                "fr": "Déchets d'exploitation et résidus des cargaisons des navires",
                "en": "Operational waste and ship cargo residues"
            }
        },
        {
            "key": "HUILUSAGE",
            "label": {
                "fr": "Huiles usagées",
                "en": "Used oils"
            }
        },
        {
            "key": "SUBPCB",
            "label": {
                "fr": "Substances dites \" PCB \"",
                "en": "Substances known as \"PCBs\""
            }
        },
        {
            "key": "EMB",
            "label": {
                "fr": "Emballages",
                "en": "Packaging"
            }
        },
        {
            "key": "FLUFRIGO",
            "label": {
                "fr": "Fluides frigorigènes utilisés dans les équipements thermodynamiques",
                "en": "Refrigerant fluids used in thermodynamic equipment"
            }
        },
        {
            "key": "PILACC",
            "label": {
                "fr": "Piles et accumulateurs",
                "en": "Batteries and accumulators"
            }
        },
        {
            "key": "PNEU",
            "label": {
                "fr": "Déchets de pneumatiques",
                "en": "Tire waste"
            }
        },
        {
            "key": "VEHIC",
            "label": {
                "fr": "Véhicules",
                "en": "Vehicles"
            }
        },
        {
            "key": "EEE",
            "label": {
                "fr": "Equipements électriques et électroniques (EEE)",
                "en": "Electrical and electronic equipment (EEE)"
            }
        },
        {
            "key": "IMPRIM",
            "label": {
                "fr": "Déchets d'imprimés papiers et de papiers à usage graphique destinés à être imprimés",
                "en": "Printed paper and graphic paper waste intended for printing"
            }
        },
        {
            "key": "LINGTEXTIL",
            "label": {
                "fr": "Déchets de produits textiles d'habillement, de chaussures ou de linge de maison destinés aux ménages",
                "en": "Textile waste from clothing, shoes, or household linens for households"
            }
        },
        {
            "key": "BIODECH",
            "label": {
                "fr": "Biodéchets",
                "en": "Biowaste"
            }
        },
        {
            "key": "CHIMENAGE",
            "label": {
                "fr": "Déchets ménagers issus de produits chimiques pouvant présenter un risque significatif pour la santé",
                "en": "Household waste from chemical products posing a significant health risk"
            }
        },
        {
            "key": "AMEUB",
            "label": {
                "fr": "Déchets d'éléments d'ameublement",
                "en": "Furniture waste"
            }
        },
        {
            "key": "BOGAZ",
            "label": {
                "fr": "Bouteilles rechargeables de gaz destinées à un usage individuel et déchets de bouteilles de gaz",
                "en": "Rechargeable gas bottles for individual use and gas bottle waste"
            }
        },
        {
            "key": "RECYNAV",
            "label": {
                "fr": "Recyclage des navires",
                "en": "Ship recycling"
            }
        },
        {
            "key": "MEPVEBO",
            "label": {
                "fr": "Déchets de papier, de métal, de plastique, de verre et de bois",
                "en": "Waste from paper, metal, plastic, glass, and wood"
            }
        },
        {
            "key": "CONS",
            "label": {
                "fr": "Déchets issus de matériaux, produits et équipements de construction",
                "en": "Waste from construction materials, products, and equipment"
            }
        },
        {
            "key": "VEGE",
            "label": {
                "fr": "Méthanisation de déchets non dangereux ou de matières végétales brutes",
                "en": "Methanization of non-hazardous waste or raw plant materials"
            }
        },
        {
            "key": "PLAST",
            "label": {
                "fr": "Produits en plastique dont l'abandon est de nature à générer des déchets terrestres et marins",
                "en": "Plastic products likely to generate land and marine waste"
            }
        },
        {
            "key": "BATO",
            "label": {
                "fr": "Déchets issus de bateaux de plaisance ou de sport",
                "en": "Waste from recreational or sports boats"
            }
        },
        {
            "key": "ALIMINV",
            "label": {
                "fr": "Produits alimentaires invendus",
                "en": "Unsold food products"
            }
        },
        {
            "key": "DECHAUTRE",
            "label": {
                "fr": "Autre type de déchet",
                "en": "Other type of waste"
            }
        },
        {
            "key": "DECHDANG",
            "label": {
                "fr": "Autres déchets dangereux",
                "en": "Other hazardous waste"
            }
        },
        {
            "key": "DECHRADIO",
            "label": {
                "fr": "Déchets radioactifs",
                "en": "Radioactive waste"
            }
        },
        {
            "key": "DECHAMI",
            "label": {
                "fr": "Déchets dangereux contenant de l'amiante",
                "en": "Hazardous waste containing asbestos"
            }
        },
        {
            "key": "*AO",
            "label": {
                "fr": "- aucune de ces options -",
                "en": "- none of these options -"
            }
        }
    ]



    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} produit, détient, collecte ou élimine des produits ou déchets suivants :(maximum : 5)
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} produces, holds, collects or disposes of the following products or waste:(maximum: 5)
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="mb-4">
                    <input
                        type="text"
                        className="w-full p-3 border-2 border-gray-200 rounded-2xl"
                        placeholder={i18n.language === 'fr' ? "Titre ou mot-clé" : "Title or keyword"}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold  rounded-xl ${prodDetenCollecteEliminDechetsQ.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc] '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(prodDetenCollecteEliminDechetsQ.length !== 0)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default ProdDetenCollecteEliminDechetsQ;
