export const updateMessage = (newMsg, setMessages) => {
    setMessages((prev) => [...prev, newMsg]);
};

const html_content = (content) => content.replace(/\n/g, "<br>");



export const update_last_system_message_tokens = (tokens, setMessages) => {
    setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[prevMessages.length - 1];
        updatedMessages[prevMessages.length - 1] = {
            ...lastMessage,
            // content: html_content(lastMessage.content + tokens),
            content: lastMessage.content + tokens,
        };
        return updatedMessages;
    });
};

export const update_last_system_message_documents = (documents, setMessages) => {
    setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[prevMessages.length - 1];
        updatedMessages[prevMessages.length - 1] = {
            ...lastMessage,
            documents: documents,
        };
        return updatedMessages;
    });
};

export const createMessage = (newContent, role, documents = [], metadatas = {}, id = null) => {
    if (!id) {
        const id = Date.now() + Math.floor(Math.random() * 1000000);
    }
    return {
      id: id,
      createdAt: Date.now(),
      content: newContent,
      role: role,
      documents: documents,
      metadatas: metadatas
    };
  }