import React, { useState, useEffect, useRef } from 'react';
import RangeSlider from './RangeSlider';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { themePopOver, updateProgressColor } from '../../utils/utils';
import { Popover } from 'flowbite-react';
import ContinueButton from './ContinueButton';

const EmployeesRangeSelector = ({ nbStep, isSCI, moveToStep, nbCandtionMicroValide, goBack, raisonSociale, isMicroEntreprise, setNbMoyenSalaries }) => {
  const [showSlider1, setShowSlider1] = useState(false);
  const [showSlider2, setShowSlider2] = useState(false);
  const [showSlider3, setShowSlider3] = useState(false);
  const [value, setValue] = useState(null);
  const [valueSelection, setValueSelection] = useState('');
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
  const [divDisplayHover, setDivDisplayHover] = useState({});
  const rangeRef = useRef(null);
  const { t, i18n } = useTranslation();


  const handleConfirm = () => {

    setNbMoyenSalaries(value);
    moveToStep('regimeTva');
  };

  const handleSliderChange = (event) => {
    setValue(event.target.value);
  };

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  const handleSelectRange = (minValue, maxValue, line) => {
    setDivDisplayHover({});
    switch (line) {
      case 1:
        setShowSlider2(false);
        setShowSlider3(false);
        setShowSlider1(true);
        break;
      case 2:
        setShowSlider1(false);
        setShowSlider3(false);
        setShowSlider2(true);
        break;
      case 3:
        setShowSlider1(false);
        setShowSlider2(false);
        setShowSlider3(true);
        break;

      default:
        break;
    }
    setMaxValue(maxValue);
    setMinValue(minValue);
    setValue((minValue + maxValue) / 2);
  };

  useEffect(() => {
    updateProgressColor(nbStep, maxValueQuestion, rangeRef);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleMobileSelect = (event) => {
    setValueSelection(event.target.value);
    if (event.target.value) {
      const [min, max, line] = event.target.value.split('-').map(Number);
      handleSelectRange(min, max, line);
    } else {
      setShowSlider1(false);
      setShowSlider2(false);
      setShowSlider3(false);
    }
  };

  const mobileOptionsEmployees = [
    { value: "0-0-1", label: 'rangeSlider.zero' },
    { value: "1-10-1", label: 'rangeSlider.range1To10' },
    { value: "11-19-1", label: 'rangeSlider.range10To19' },
    { value: "20-49-2", label: 'rangeSlider.range20To49' },
    { value: "50-249-2", label: 'rangeSlider.range50To249' },
    { value: "250-999-2", label: 'rangeSlider.range250To999' },
    { value: "1000-4999-3", label: 'rangeSlider.range1000To4999' },
    { value: "5000-9999-3", label: 'rangeSlider.range5000To9999' },
    { value: "10000-60000-3", label: 'rangeSlider.moreThan10000' }
  ];



  return (
    <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
      <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


      <div className='max-w-[800px] -mt-2'>

        <h1 className='text-2xl text-[#1e4690] my-6'>
          {i18n.language === 'fr' &&

            <>
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>Effectif moyen mensuel (EMM) et effectif moyen annuel (EMA)
                        Il s'agit d'une moyenne rapportée à l'année et au mois. Pour calculer l'effectif moyen annuel (EMA), il faut d'abord calculer l'effectif moyen mensuel (EMM) pour chaque mois de l'année passée.</p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  Effectif moyen
                </a>
              </Popover>{' '}
              de {raisonSociale} au cours de l'exercice (ou l'effectif prévisionnel si l'entreprise est en devenir) :
            </>
          }

          {i18n.language === 'en' &&
            <>
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>Average monthly headcount (AMH) and average annual headcount (AHS) These are averages for the year and the month. To calculate the average annual headcount (AAL), the average monthly headcount (AMH) must first be calculated for each month of the previous year.

                      </p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  Average workforce
                </a>
              </Popover>{' '}
              of {raisonSociale}
              during the financial year (or its projected number of employees if the company is being set up):
            </>

          }

        </h1>
        <p className='text-[#aaaaaa]'>{t('rangeSlider.title2')}</p>
      </div>
      {isMobile ? (
        <select
          className="w-full p-3 my-4 text-[#1e4690] border border-gray-300 rounded-md"
          onChange={handleMobileSelect}
        >
          <option value="">{t('revenu.selectEffectif')}</option>
          {mobileOptionsEmployees.map(({ value, label }) => (
            <option key={value} value={value} dangerouslySetInnerHTML={createMarkup(t(label))}></option>
          ))}
        </select>
      ) : (

        <div className="buttons">
          <div className="btn-row text-[#1e4690]">
            <div className={`btn-ca text-sm ${divDisplayHover[1] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(0, 0, 1); setDivDisplayHover({ 1: true }); }} role="button"
              dangerouslySetInnerHTML={createMarkup(t('rangeSlider.zero'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[2] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(1, 10, 1); setDivDisplayHover({ 2: true }); }} role="button"
              dangerouslySetInnerHTML={createMarkup(t('rangeSlider.range1To10'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[3] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(11, 19, 1); setDivDisplayHover({ 3: true }); }} role="button"
              dangerouslySetInnerHTML={createMarkup(t('rangeSlider.range10To19'))}></div>
          </div>
          {/* Affichage conditionnel du slider en fonction de l'état 'showSlider' */}
          {showSlider1 && value !== 0 && (
            <RangeSlider
              minValue={minValue}
              maxValue={maxValue}
              value={value}
              onSliderChange={handleSliderChange}
              isEmployees={true}
            />
          )}


          <div className="btn-row text-[#1e4690]">
            <div className={`btn-ca text-sm ${divDisplayHover[4] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(20, 49, 2); setDivDisplayHover({ 4: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('rangeSlider.range20To49'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[5] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(50, 249, 2); setDivDisplayHover({ 5: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('rangeSlider.range50To249'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[6] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(250, 999, 2); setDivDisplayHover({ 6: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('rangeSlider.range250To999'))}></div>
          </div>

          {showSlider2 && (
            <RangeSlider
              minValue={minValue}
              maxValue={maxValue}
              value={value}
              onSliderChange={handleSliderChange}
              isEmployees={true}
            />
          )}

          <div className="btn-row text-[#1e4690]">
            <div className={`btn-ca text-sm ${divDisplayHover[7] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(1000, 4999, 3); setDivDisplayHover({ 7: true }); }} dangerouslySetInnerHTML={createMarkup(t('rangeSlider.range1000To4999'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[8] ? "border-2 border-[#515356] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(5000, 9999, 3); setDivDisplayHover({ 8: true }); }} dangerouslySetInnerHTML={createMarkup(t('rangeSlider.range5000To9999'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[9] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(10000, 60000, 3); setDivDisplayHover({ 9: true }); }} dangerouslySetInnerHTML={createMarkup(t('rangeSlider.moreThan10000'))}></div>
          </div>


          {showSlider3 && (
            <RangeSlider
              minValue={minValue}
              maxValue={maxValue}
              value={value}
              onSliderChange={handleSliderChange}
              isEmployees={true}
            />
          )}


        </div>
      )}

      {showSlider1 && isMobile && value !== 0 && (
        <RangeSlider
          minValue={minValue}
          maxValue={maxValue}
          value={value}
          onSliderChange={handleSliderChange}
          isEmployees={true}
        />
      )}

      {showSlider2 && isMobile && (
        <RangeSlider
          minValue={minValue}
          maxValue={maxValue}
          value={value}
          onSliderChange={handleSliderChange}
          isEmployees={true}
        />
      )}

      {showSlider3 && isMobile && (
        <RangeSlider
          minValue={minValue}
          maxValue={maxValue}
          value={value}
          onSliderChange={handleSliderChange}
          isEmployees={true}
        />
      )}

      <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-4'>
        <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
          {t('revenu.button_back')}
        </button>

        <ContinueButton
          isValid={(value !== null || (isMobile && valueSelection !== ''))}
          onConfirm={handleConfirm}
        // isLastQst={isMicroEntreprise}
        />
      </div>
    </div>
  );
};

export default EmployeesRangeSelector;
