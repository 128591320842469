import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const ExpositionTravailleurs = ({ nbStep, isSCI, expositionTravailleurs, setExpositionTravailleurs, nbCandtionMicroValide, moveToStep, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');


    const handleConfirm = () => {
        if (expositionTravailleurs.length > 0 && !expositionTravailleurs.includes("*AO")) {
            moveToStep('nbSalarieExpositionPenibilite');
        } else {
            moveToStep('controleRepoasHebdomadaire')
        }
    };

    const options = [
        { key: 'PENIBILITE', label: { fr: 'un ou plusieurs facteurs de pénibilité', en: 'one or more risk factors' } },
        { key: 'CMR', label: { fr: 'des agents chimiques cancérogènes, mutagènes ou toxiques pour la reproduction (CMR)', en: 'chemical agents that are carcinogenic, mutagenic or toxic to reproduction (CMR)' } },
        { key: '*AO', label: { fr: '-- aucune de ces options', en: '-- none of these options' } },
    ];

    const toggleSelectedValue = (key) => {
        setExpositionTravailleurs(prevState => {
            // Si on clique sur '*AO'
            if (key === "*AO") {
                // Si '*AO' est déjà sélectionné, on le désélectionne
                if (prevState.includes("*AO")) {
                    return [];
                }
                // Sinon, on sélectionne uniquement '*AO'
                return ["*AO"];
            }

            // Si on clique sur une autre option
            // Si '*AO' était sélectionné, on le retire et on sélectionne la nouvelle option
            if (prevState.includes("*AO")) {
                return [key];
            }

            // Si l'option cliquée était déjà sélectionnée, on la retire
            if (prevState.includes(key)) {
                return prevState.filter(item => item !== key);
            }

            // Sinon, on ajoute la nouvelle option
            return [...prevState, key];
        });
    };

    // const toggleSelectedValue = (key) => {

    //     if (expositionTravailleurs.includes(key)) {
    //         setExpositionTravailleurs(expositionTravailleurs.filter((item) => item !== key));
    //     } else {
    //         setExpositionTravailleurs([...expositionTravailleurs, key]);
    //     }
    // };



    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Des salariés de {raisonSociale} sont exposés à un ou plusieurs facteurs de pénibilité ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Are {raisonSociale} employees exposed to one or more risk factors?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold rounded-xl ${expositionTravailleurs.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(expositionTravailleurs.length !== 0)}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default ExpositionTravailleurs;
