import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import NumberInputWithSeparator from './NumberInputWithSeparator';
import ContinueButton from './ContinueButton';


const TotalBilan = ({ nbStep, isSCI, isComanyCommercial, moveToStep, nbCandtionMicroValide, goBack, totalBilanQ, setTotalBilanQ, raisonSociale, isMicroEntreprise, isSkippable = true }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        isComanyCommercial ? moveToStep('totalCapitauxPropres') : moveToStep('SiegeLocauxEnCommunX');
    };

    const handleSkip = () => {
        setTotalBilanQ(null); // ou une autre valeur par défaut selon vos besoins
        handleConfirm();
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);


    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-4 mt-6'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        "Total bilan comptable de l'entreprise au dernier exercice clos (en euros) :"
                    }

                    {i18n.language === 'en' &&
                        "Company's balance sheet total for the last financial year (in euros) :"
                    }

                </h1>
            </div>

            <NumberInputWithSeparator setValue={setTotalBilanQ} value={totalBilanQ} />

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(totalBilanQ !== null)}
                    onConfirm={handleConfirm}
                />
            </div>
            {isSkippable && (
                <button
                    onClick={handleSkip}
                    className="text-gray-500 px-12 py-3 hover:text-[#006acc] transition-colors duration-300 underline"
                >
                    {t('skip', 'Passer')}
                </button>
            )}
        </div>
    );
};

export default TotalBilan;
