import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import BientotDispo from "./pages/BientotDispo";
import { ColorsContextProvider } from "./context/GlobalContext";
import { applyActionCode } from "firebase/auth";
import { useAppContext } from "./context/AppContext";
import ProtectedRoute from "./utils/ProtectedRoute";
import SiretPage from "./pages/SiretPage";
import RecapWorkspac from "./pages/RecapWorkspace";
import ExpertsPage from "./pages/expertsPage";
import TarifPage from "./pages/TarifPage";
import LoginComponent from "./components/LoginComponent";
import WaitingForConfirmation from "./pages/WaitingForConfirmation";
import MentionsLegales from "./pages/MentionsLegales";
import PolitiqueConfidentialite from "./pages/PolitiqueConfidentialite";
import QualitePersonnes from "./pages/QualitePersonnes";
import ConditionsGenerales from "./pages/ConditionsGenerales";
import ModalitesReferencement from "./pages/ModalitesReferencement";
import ReglesRecommandations from "./pages/ReglesRecommandations";

const App = () => {
  const subdomain = window.location.hostname.split(".")[0];
  const { currentUser, legalContent } = useAppContext();
  if (window.location.search.includes("mode=verifyEmail")) {
    const verifyEmail = async () => {
      try {
        const url = window.location.href;
        await applyActionCode(new URL(url).searchParams.get("oobCode"));
        alert("Email verified! You can now log in.");
      } catch (error) {
        alert(error.message);
      }
    };
    verifyEmail();
  }

  return (
    <ColorsContextProvider>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/questionnaire" element={<SiretPage />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/workspace" element={<RecapWorkspac />} />
          <Route path="/experts" element={<ExpertsPage />} />
          <Route path="/tarif" element={<TarifPage />} />

          # used FooterPageTo
          <Route path="/mentionslegales" element={<MentionsLegales legalContent={legalContent.mentionsLegales} />} />
          <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite legalContent={legalContent.politiqueConfidentialite} />} />
          <Route path="/qualite-personnes-autorisees-a-deposer-une-offre" element={<QualitePersonnes legalContent={legalContent.QualitePersonnes} />} />
          <Route path="/conditions-generales" element={<ConditionsGenerales legalContent={legalContent.ConditionsGenerales} />} />
          <Route path="/modalites-referencement" element={<ModalitesReferencement legalContent={legalContent.ModalitesReferencement} />} />
          <Route path="/regles-recommandations-redaction-offre-contenu" element={<ReglesRecommandations legalContent={legalContent.ReglesRecommandations} />} />

          <Route path="/waiting-for-confirmation" element={
            <ProtectedRoute>
              <WaitingForConfirmation />
            </ProtectedRoute>
          } />

          {/* {subdomain === "infogreffe" && (<Route path="registre-formalites" element={
            <ProtectedRoute>
              <RegistreFormalites />
            </ProtectedRoute>
          } />)
          } */}

          <Route path="*" element={<BientotDispo />} />
        </Routes>
      </div>
    </ColorsContextProvider>
  );
};

export default App;
