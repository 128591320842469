import React, { useState } from 'react';

const ProgressBar = ({ value, maxValueQuestion, rangeRef }) => {
    return (
        <div className='row mt-12'>
            <div className="ca-progress-bar">
                <input
                    ref={rangeRef}
                    type="range"
                    className="ca-progress-input"
                    min="0"
                    max={maxValueQuestion}
                    value={value}
                    readOnly
                />
                <div className="ca-progress-bubble">
                    {value} sur {maxValueQuestion}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;