import React, { useRef, useState } from 'react';
import illustration_cahier from '../../assets/illustration_cahier.svg';
import illustration_dashboard from '../../assets/illustration_dashboard.svg';
import DownloadButton from './DownloadButton';
import { useAppContext } from '../../context/AppContext';
import { getEnvConfig } from '../../utils/config';
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from 'react-router-dom';
const stripePromise = loadStripe("pk_test_51PuH4IFogUVbkwou1z1Lp1t2Zu4jdzkvnsLBmAapXlvfNJVSeaErHBDb0a6xZ76JqBMbWuJAYc1OqvDoY5MMzPR500hsOt7fH3");


const ModalPayments = ({ isOpen, onClose, result }) => {
    const [activeTab, setActiveTab] = useState('compliance');
    const modalRef = useRef();
    let navigate = useNavigate();

    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };


    const goLogin = () => {
        sessionStorage.setItem('redirectPath', '/workspace');
        navigate('/login');
        return;
    }

    const ComplianceReport = () => (
        <div className="bg-white px-4 md:px-8 py-2 md:rounded-3xl rounded-b-3xl">
            <div className='flex justify-center mb-4'>
                <div className='bg-[#F0F0F0] rounded-full p-8'>
                    <img src={illustration_cahier} alt='' className="w-16 h-16" />
                </div>
            </div>
            <h2 className="text-xl md:text-3xl font-semibold mb-2">Rapport de conformité</h2>
            <p className="mb-4">Téléchargez le rapport complet et détaillé sur vos obligations légales comprenant :</p>
            <ul className="space-y-2">
                <li>✔️ La checklist de vos obligations</li>
                <li>✔️ Les conséquences de non-conformité</li>
                <li>✔️ L'indice de risque global d'exposition au risque de non-conformité</li>
                <li>✔️ Les solutions les plus pertinentes</li>
                <li>✔️ Les échéances, lorsqu'applicable</li>
            </ul>
            <DownloadButton result={result} />
        </div>
    );

    const PremiumOffer = () => {
        const [selectedOption, setSelectedOption] = useState({
            reports: 100,
            price: 980
        });

        const options = [
            { reports: 20, price: 339 },
            { reports: 50, price: 689 },
            { reports: 100, price: 980 },
            { reports: 500, price: 2980 }
        ];

        const handleOptionChange = (event) => {
            const selected = options.find(opt => opt.reports === parseInt(event.target.value));
            setSelectedOption(selected);
        };
        const { currentUser } = useAppContext();

        const handleSubscribe = async () => {
            const user = currentUser;
            if (!user) {
                setActiveTab("souscrire")
                return;
            }
            const userEmail = user.email;

            try {
                const stripe = await stripePromise;
                const { apiUrl } = getEnvConfig();
                console.log("apiUrl: ", apiUrl + 'run_simulator')
                const response = await fetch(apiUrl + "create-checkout-session", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        amount: selectedOption.price * 100,
                        pack_size: selectedOption.reports,
                        user_email: userEmail,
                        result: result  // Si nécessaire
                    }),
                });

                const session = await response.json();
                await stripe.redirectToCheckout({ sessionId: session.id });
            } catch (error) {
                console.error('Erreur lors de la souscription:', error);
            }
        };

        return (
            <div className="bg-[#161C67] text-white px-4 md:px-8 py-2 md:rounded-3xl">
                <div className='flex justify-center mb-4'>
                    <div className='bg-[#F0F0F0] rounded-full p-8'>
                        <img src={illustration_dashboard} alt='' className="w-16 h-16" />
                    </div>
                </div>

                <h2 className="text-xl md:text-3xl font-semibold mb-2">Pack Expert</h2>
                <p className="mb-2">Accédez jusqu'à <select
                    id="reportSelect"
                    value={selectedOption.reports}
                    onChange={handleOptionChange}
                    className="w-20 p-1 text-[#161C67] rounded"
                >
                    {options.map(option => (
                        <option key={option.reports} value={option.reports}>
                            {option.reports}
                        </option>
                    ))}
                </select>  rapports de conformité comprenant chacun :</p>
                <ul className="space-y-2">
                    <li>✔️ La checklist des obligations de l'entreprise</li>
                    <li>✔️ Les conséquences de non-conformité</li>
                    <li>✔️ L'indice de risque global d'exposition au risque de non-conformité</li>
                    <li>✔️ Les solutions les plus pertinentes</li>
                    <li>✔️ Les échéances, lorsqu'applicable</li>
                    <li>✔️ L'état de solvabilité de l'entreprise</li>
                    <li>✔️ Un suivi en temps réel du taux de conformité</li>
                </ul>


                <p className="text-3xl md:text-4xl font-bold my-1">{selectedOption.price}€ <span className='text-xl font-bold relative bottom-3'>HT</span> /<span className='text-xl md:text-xl font-bold relative'>AN</span></p>
                <div className="flex justify-center">
                    <button
                        className="bg-white font-bold text-[#161C67] px-16 py-2 hover:scale-105 rounded-full"
                        onClick={handleSubscribe}
                    >
                        Souscrire
                    </button>
                </div>
            </div>
        );
    };



    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
            onClick={handleOverlayClick}
        >
            {activeTab === "souscrire" ? (
                <>
                    <div
                        className="relative bg-white rounded-3xl text-[#006acc] shadow-lg w-full max-w-md mx-auto p-6"
                        ref={modalRef}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={() => setActiveTab('compliance')}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl font-bold"
                        >
                            &times;
                        </button>
                        <div className="flex flex-col items-center">
                            <h2 className="text-2xl text-center font-bold mb-4">
                                Vous devez être connecté pour souscrire à un pack Expert
                            </h2>
                            <div className="flex justify-center gap-3">
                                <button
                                    className="bg-white font-bold text-red-500 border border-red-500 px-16 py-2 hover:scale-105 rounded-full"
                                    onClick={() => setActiveTab('compliance')}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="bg-white font-bold text-[#006acc] border border-[#006acc] px-16 py-2 hover:scale-105 rounded-full"
                                    onClick={goLogin}
                                >
                                    Me connecter
                                </button>
                            </div>
                        </div>
                    </div>
                </>

            ) : (
                <>
                    <div
                        className="bg-white md:rounded-3xl rounded-b-3xl shadow-lg w-full max-w-4xl relative max-h-[90vh] md:max-h-[80vh] overflow-y-auto md:overflow-visible"
                        ref={modalRef}
                        onClick={(e) => e.stopPropagation()}
                    >

                        {/* Boutons de navigation sur mobile */}
                        <div className="md:hidden flex">
                            <button
                                className={`w-1/2 py-2 ${activeTab === 'compliance' ? '' : 'bg-gray-200 '}`}
                                onClick={() => setActiveTab('compliance')}
                            >
                                Rapport de conformité
                            </button>
                            <button
                                className={`w-1/2 py-2 ${activeTab === 'premium' ? ' bg-[#161C67] text-white' : 'bg-gray-200'}`}
                                onClick={() => setActiveTab('premium')}
                            >
                                Pack Expert
                            </button>
                        </div>
                        {/* Contenu du modal */}
                        <div className="md:flex relative">
                            {/* Rapport de conformité */}
                            <div className={`w-full md:w-1/2 md:rounded-3xl rounded-b-3xl ${activeTab === 'compliance' ? '' : 'hidden md:block'}`}>
                                <ComplianceReport />
                            </div>
                            {/* Offre Premium */}
                            <div className={`w-full md:w-1/2 md:absolute md:right-0 md:-top-14 ${activeTab === 'premium' ? '' : 'hidden md:block'}`}>
                                <div className="bg-white md:rounded-3xl rounded-b-3xl shadow-lg">
                                    <PremiumOffer />
                                </div>
                            </div>
                        </div>

                        {/* Bouton de fermeture */}
                        <button
                            onClick={onClose}
                            className="absolute top-0 right-0 md:-top-14 font-bold mt-3 mr-4 text-[#161C67] md:text-white scale-125 hover:text-gray-800"
                        >
                            ✕
                        </button>
                    </div>
                </>
            )
            }

        </div>
    );
};

export default ModalPayments;
