// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

console.log("AUTH_DOMAIN: ", process.env.REACT_APP_FIREBASE_AUTH_DOMAIN)

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const goggleAuthProvider = new GoogleAuthProvider();

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebaseApp);

const storage = getStorage(firebaseApp);
export const getUserData = async (userEmail) => {
  try {
    const userRef = db.collection("users").doc(userEmail);
    const doc = await userRef.get();
    return doc.exists ? doc.data() : null;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

export { auth, goggleAuthProvider, db, storage };
