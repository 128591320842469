import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { signOut } from "firebase/auth";
import { useAppContext } from "./AppContext";
import { useNavigate, useLocation } from "react-router-dom";
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const subdomain = window.location.hostname.split('.')[0];
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentUser, setIsAdmin } = useAppContext();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          console.log("AuthContextProvider user:", user)
          const token = await user.getIdTokenResult();
          setIsAdmin(token.claims.admin ? true : false);
          setCurrentUser(user)
          // console.log("token.claims.admin:", token.claims.admin)
          // console.log("token.claims.admin:", token)

        } catch (error) {
          console.error("Error getting token claims:", error);
          setIsAdmin(false);
        }
      } else {
        setCurrentUser(null);
        setIsAdmin(false);
        console.log("AuthContextProvider:")
        // if (subdomain !== "oliver" && location.pathname !== "/auth/login") {
        //   navigate("/auth/login");
        // }
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth, navigate, location.pathname, subdomain, setIsAdmin, setCurrentUser]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null)
    } catch (error) {
      console.error("Error during sign out:", error);
    }
  }

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-50 text-gray-800">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900"></div>
        <span className="sr-only">Chargement...</span>
      </div>
    );
  }


  return (
    <AuthContext.Provider value={{ handleLogout, auth }}>
      {children}
    </AuthContext.Provider>
  );
};
