// NavigationBar.js
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import infogreffe_logo from '../assets/infogreffeMCL-white.png';
import infogreffe_logo_claire from '../assets/infogreffeMCL-color.png';
import france_logo from '../assets/french.png';
import uk_logo from '../assets/uk_logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import { TiThMenu } from "react-icons/ti";
import { GrUserExpert } from "react-icons/gr";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { AuthContext } from '../context/AuthContext';
import { useAppContext } from '../context/AppContext';
import UserDropdownMenu from './UserDropdownMenu';
import { Dropdown, DropdownItem } from 'flowbite-react';
import { IoPricetagsSharp } from "react-icons/io5";
import { IoPricetagsOutline } from "react-icons/io5";

const NavigationBar = ({
  background = '',
  isConnection = false,
  isQuestionnaire = false,
  isPageExpert = false
}) => {

  const { t, i18n } = useTranslation();
  const { currentUser } = useAppContext();

  const [hasScrolled, setHasScrolled] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < 768;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  let navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const location = useLocation();

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleContactClick = () => {
    navigate('/tarif');
  };

  // Ajout d'un état pour gérer la visibilité du sous-menu
  const [isExpertsSubMenuOpen, setIsExpertsSubMenuOpen] = useState(false);

  // Fonction pour basculer la visibilité du sous-menu
  const toggleExpertsSubMenu = () => {
    setIsExpertsSubMenuOpen(!isExpertsSubMenuOpen);
  };

  const handleConnectClick = () => {
    navigate('/login');
  };


  const currentLanguage = i18n.language;
  const otherLanguage = currentLanguage === 'fr' ? 'en' : 'fr';
  const flagToShow = otherLanguage === 'fr' ? france_logo : uk_logo;
  const altText = otherLanguage === 'fr' ? 'FR' : 'EN';

  const my_class = background ? "font-bold text-[#372B6B] hover:border-b-2 hover:border-[#372B6B]" : "text-white font-bold hover:text-[#5FFE96] hover:border-b-2 hover:border-[#5FFE96]"
  return (
    <>
      <nav className={`fixed top-0 left-0 right-0 z-50 flex justify-between items-center py-4 px-4 sm:px-6 md:px-8 lg:px-12 transition-colors duration-1000 ${background === '' && hasScrolled ? 'bg-[#001046]' : `bg-[#0F6ACC]`} ${background ? `bg-${background}` : ''} `}>
        <div>
          <a href="/" aria-label="Home">
            {background ?
              <img src={infogreffe_logo_claire} alt="infogreffe_logo" className="h-10 scale-125 cursor-pointer" />
              :
              <img src={infogreffe_logo} alt="infogreffe_logo" className="h-10 scale-125 cursor-pointer" />
            }
          </a>
        </div>
        {!isMobile && (
          <div className="flex space-x-6">
            {!isQuestionnaire &&
              <>
                {/* Modification ici */}
                <div className="relative">
                  <Dropdown label="" dismissOnClick={false} renderTrigger={() =>
                    <button className={my_class}>
                      <div className='flex justify-between'>
                        <div>
                          {t('experts')} {/* Ajout de la flèche (exemple avec FontAwesome, assurez-vous d'inclure la librairie) */}

                        </div>
                        <div className='items-center self-center ml-2 hover:scale-150'>
                          <FaChevronDown />
                        </div>

                      </div>
                    </button>
                  }>
                    <DropdownItem className="block px-4 py-2 w-full text-left text-[#1e4690] font-bold hover:bg-gray-100" onClick={() => navigateToPage('/experts')}>
                      {t('directory')}
                    </DropdownItem>
                    <DropdownItem className="block px-4 py-2 w-full text-left text-[#1e4690] font-bold hover:bg-gray-100" onClick={() => navigateToPage('/inscription')}>
                      {t('registration')}
                    </DropdownItem>
                  </Dropdown>

                </div>
                {/* Fin de la modification */}
                <button className={my_class} onClick={handleContactClick}>
                  {t('tarif')}
                </button>
              </>
            }
          </div >
        )}
        <div className="flex space-x-4 items-center">
          {!currentUser && !isQuestionnaire && !isMobile && (
            <button
              onClick={handleConnectClick}
              className="px-4 py-2 rounded-3xl border-2 border-[#5FFE96] bg-transparent font-semibold text-[#5FFE96] hover:bg-[#5FFE96] hover:text-[#1e4690]"
            >
              {t('connect')}
            </button>

          )}

          <button onClick={() => changeLanguage(otherLanguage)} className="flex items-center">
            <img src={flagToShow} alt={altText} className="h-10 mx-2" />
          </button>

          {currentUser &&
            <UserDropdownMenu
              handleContactClick={handleContactClick}
            />
          }

          {isMobile && !isQuestionnaire && (
            <button onClick={toggleMenu} className="text-white font-bold">
              <TiThMenu size={30} />
            </button>
          )}
        </div>
      </nav >

      {
        isMenuOpen && (
          <div className={`relative bg-white shadow-lg rounded-lg px-5 pt-4 pb-2 w-full ${isPageExpert && 'mt-14'}`}>
            {!isQuestionnaire &&
              <>
                {/* Ajout du sous-menu pour "experts" dans le menu mobile */}
                <div className="relative">
                  <Dropdown label="" dismissOnClick={false} theme={{ "placement": "-10px" }} renderTrigger={() =>
                    <button className="flex items-center justify-start px-4 py-2 text-left text-[#1e4690] font-bold border-b w-full">
                      <GrUserExpert size={25} className="mr-2" />
                      {t('experts')}
                      <FaChevronDown />
                    </button>
                  }>
                    <DropdownItem className="block px-4 py-2 w-full text-left text-[#1e4690] font-bold hover:bg-gray-100" onClick={() => navigateToPage('/experts')}>
                      {t('directory')}
                    </DropdownItem>
                    <DropdownItem className="block px-4 py-2 w-full text-left text-[#1e4690] font-bold hover:bg-gray-100" onClick={() => navigateToPage('/inscription')}>
                      {t('registration')}
                    </DropdownItem>
                  </Dropdown>


                </div>
                {/* Fin de l'ajout */}
                <button className="flex items-center justify-start px-4 py-2 text-left text-[#1e4690] font-bold border-b w-full" onClick={handleContactClick} >
                  <IoPricetagsOutline size={25} className="mr-2" />
                  {t('tarif')}
                </button>
                <button
                  onClick={handleConnectClick}
                  className="py-2 px-10 rounded-3xl justify-end border-2 border-[#5FFE96] mt-2 bg-transparent font-semibold text-[#5FFE96] hover:bg-[#5FFE96] hover:text-[#1e4690]"
                >
                  {t('connect')}
                </button>
              </>
            }
          </div >
        )
      }
    </>
  );
};

export default NavigationBar;